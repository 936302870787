import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchMessages } from '../../pages/messages/messagesSlice';

const Poller = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMessages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Poller;
