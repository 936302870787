import { createSelector, createSlice } from '@reduxjs/toolkit';
import { apiClientV2 } from '@/app/axios.config';
import { getSelectedCustomer } from '@/app/slices/userSlice';
import { NON_CUSTOMER_CUSTOMER_ID } from '@/helpers/constants';
import { filter } from 'lodash';

const messagesSlice = createSlice({
  name: 'messages',

  initialState: {
    messages: [],
  },

  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { setMessages } = messagesSlice.actions;

export const fetchMessages =
  (reload = false) =>
  (dispatch, getState) => {
    const { customerId } = getSelectedCustomer(getState());
    return (
      customerId !== NON_CUSTOMER_CUSTOMER_ID &&
      apiClientV2
        .get('messages', {
          params: {
            customerId,
            reload,
          },
        })
        .then((response) => response?.data?.data[0])
        .then((data) => dispatch(setMessages(data)))
    );
  };

export const getOpenMessages = createSelector(
  [(state) => state.messages.messages],
  (messages) => {
    return filter(messages, (m) => m.status.key !== 5);
  }
);

export default messagesSlice.reducer;
