import localforage from 'localforage';
import { SignoutCallbackComponent } from 'redux-oidc';
import PageLoader from 'ui/components/PageLoader';
import userManager from '../../app/userManager';

const Signout = () => {
  const successCallback = async () => {
    try {
      console.log(`persist:me_${import.meta.env.VITE_APP_ME_ENV}`);
      await localforage.removeItem(
        `persist:me_${import.meta.env.VITE_APP_ME_ENV}`,
      );
      userManager.signoutRedirect();
      userManager.clearStaleState();
      delete (window as any).isOrganization;
      //TODO: Visa en bekräftelse för användaren (t.ex., omdirigera till en utloggningsbekräftelsessida)
    } catch (error) {
      console.error('Error during logout:', error);
      await localforage.removeItem(
        `persist:me_${import.meta.env.VITE_APP_ME_ENV}`,
      );
      userManager.signoutRedirect();
      userManager.clearStaleState();
      userManager.removeUser();
      delete (window as any).isOrganization;
      //TODO: Visa ett felmeddelande för användaren (t.ex., omdirigera till en felmeddelandesida)
    }
  };

  return (
    // @ts-ignore redux-oidc differs in type definitions and actual implementation, no children prop in ts but required in js
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={successCallback}
    >
      <PageLoader title='Loggar ut...' />
    </SignoutCallbackComponent>
  );
};

export default Signout;
