import CleanLayout from '@/layout/CleanLayout';
import { PrimaryButton } from 'ui';
import BorderHeading from 'ui/components/BorderHeading';
import { Box, Link, Typography } from 'ui/mui';

const Error = () => {
  return (
    <CleanLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BorderHeading title='Oops! Något gick fel...' />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: { xs: 'center', md: 'left' },
            alignItems: 'center',
            my: 4,
            gap: 2,
          }}
        >
          <PrimaryButton onClick={() => window.location.assign('/callback')}>
            Försök igen
          </PrimaryButton>
          <Typography variant='body1'>
            eller <Link href='/signout'>logga ut</Link>
          </Typography>
        </Box>
      </Box>
    </CleanLayout>
  );
};

export default Error;
