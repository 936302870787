import { ButtonProps, Button as MuiButton, styled } from "@mui/material";
import { buttonRadius } from "../theme/theme";

const StyledButton = styled(MuiButton)`
  border-radius: ${buttonRadius};
  text-transform: none;
`;

export const PrimaryButton = ({
  variant = "contained",
  ...props
}: ButtonProps) => (
  <StyledButton
    {...props}
    color="secondary"
    disableElevation
    variant={variant}
  />
);

const StyledSecondaryButton = styled(StyledButton)`
  border-color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
    border-color: ${({ theme }) => theme.palette.secondary.dark};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const SecondaryButton = ({
  variant = "outlined",
  ...props
}: ButtonProps) => (
  <StyledSecondaryButton {...props} color="secondary" variant={variant} />
);

export const TextButton: React.FC<ButtonProps & { underline?: boolean }> = ({
  underline,
  ...rest
}) => (
  <StyledButton
    {...rest}
    variant="text"
    color="secondary"
    sx={{
      ...rest.sx,
      textDecoration: underline ? "underline" : "none",
    }}
  />
);
