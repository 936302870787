import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";
import Logo from "../logo/Logo";
import FooterWave from "./FooterWave";
import { lightModeColors } from "../../theme/theme";

type PageFooterProps = {
  children?: ReactNode;
  hideLogo?: boolean;
};

const PageFooter = ({ children, hideLogo }: PageFooterProps) => {
  const theme = useTheme();
  
  return (
    <Box>
      <FooterWave />
      <Box
        sx={{
          display: "flex",
          background: lightModeColors.primary.dark,
          flexDirection: { xs: "column", md: "row" },
          textAlign: { xs: "center", md: "left" },
          py: { xs: 8, md: 2 },
          px: 2,
        }}
      >
        <Box sx={{ flexGrow: { md: 1 }, color: "white" }}>{children}</Box>
        {!hideLogo && (
          <Box sx={{ color: "#FFF", ml: "auto" }}>
            <Logo colorless textColor={theme.palette.common.white} size={69} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PageFooter;
