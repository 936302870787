import { createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../../app/axios.config';

const distanceHeatingContractSlice = createSlice({
  name: 'fjv-contract',
  initialState: {
    contractHasBeenSent: false,
  },
  reducers: {
    setContractHasBeenSent: (state) => {
      state.contractHasBeenSent = true;
    },
  },
});

const { setContractHasBeenSent } = distanceHeatingContractSlice.actions;

export const postContractType = (body) => (dispatch) => {
  return apiClient
    .post('messages', body)
    .then((response) => dispatch(setContractHasBeenSent()));
};

export default distanceHeatingContractSlice.reducer;
