import { svSE } from "@mui/material/locale";
import {
  alpha,
  createTheme as createMuiTheme,
  Theme,
} from "@mui/material/styles";
import { svSE as dateSvSE } from "@mui/x-date-pickers/locales";
import { blendHexColorsToRgb, hexToRgba } from "../utils/color";
import { pxToRem } from "../utils/text";

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
  }
  interface PaletteOptions {
    accent: PaletteOptions["primary"];
  }
}

export type PaletteColors =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "accent";

export const buttonRadius = "24px";
export const listBoxRadius = "4px";

const malarenergiSansFamily = "Malarenergi Sans, sans-serif";

export const createTheme = (darkMode = false, baseUrl = ""): Theme => {
  const defaultTheme = createMuiTheme();
  const primaryColors = darkMode
    ? {
        light: "#B2BECA",
        main: "#99A9B9",
        dark: "#7F93A7", //#002850
        contrastText: "#121212",
      }
    : {
        light: "#667E96",
        main: "#193D61",
        dark: "#002850",
        contrastText: "#FFFFFF",
      };
  const secondaryColors = darkMode
    ? {
        light: "#99BBEF",
        main: "#80AAEB",
        dark: "#4C88E3",
      }
    : {
        light: "#4C88E3",
        main: "#0055D7",
        dark: "#0044AC",
      };
  const textPrimary = darkMode ? "#FFFFFF" : "#121212";
  const textSecondary = darkMode
    ? "rgba(255, 255, 255, 0.7)"
    : "rgba(18, 18, 18, 0.7)";
  const background = darkMode
    ? { default: "#000913", paper: "#00162C" }
    : { default: "#FFFFFF", paper: "#F5F0EB" };

  // Constants for success, error, info, and warning
  const successColors = darkMode
    ? {
        light: "#8DBBA0",
        main: "#609F7A",
        dark: "#338454",
      }
    : {
        light: "#499167",
        main: "#1C7641",
        dark: "#165E34",
      };

  const errorColors = darkMode
    ? {
        light: "#E29494",
        main: "#D76868",
        dark: "#D15353",
      }
    : {
        light: "#D76868",
        main: "#C62828",
        dark: "#9E2020",
      };

  const infoColors = secondaryColors;

  const warningColors = darkMode
    ? {
        light: "#FFE18C",
        main: "#FFDA6F",
        dark: "#CCAE59",
      }
    : {
        light: "#FFE18C",
        main: "#FFDA6F",
        dark: "#998343",
      };

  const theme = createMuiTheme(
    {
      palette: {
        mode: darkMode ? "dark" : "light",
        background: background,
        primary: primaryColors,
        secondary: secondaryColors,
        accent: {
          main: "#FF6932",
        },
        success: successColors,
        warning: warningColors,
        error: errorColors,
        info: infoColors,
        text: {
          primary: textPrimary,
          secondary: textSecondary,
        },
        action: {
          active: darkMode ? "#FFFFFF8A" : "#1212128A",
        },
      },
      typography: {
        fontFamily:
          'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        h1: {
          fontSize: pxToRem(36),
          fontWeight: 800,
          fontFamily: malarenergiSansFamily,
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: pxToRem(54),
          },
        },
        h2: {
          fontSize: pxToRem(32),
          fontWeight: 700,
          fontFamily: malarenergiSansFamily,
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: pxToRem(34),
          },
        },
        h3: {
          fontSize: pxToRem(28),
          fontWeight: 700,
          fontFamily: malarenergiSansFamily,
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: pxToRem(30),
          },
        },
        h4: {
          fontSize: pxToRem(24),
          fontWeight: 700,
        },
        h5: {
          fontSize: pxToRem(20),
          fontWeight: 700,
          color: textPrimary,
        },
        h6: {
          fontSize: pxToRem(18),
          fontWeight: 400,
          color: textSecondary,
        },
        body1: {
          fontSize: pxToRem(16),
        },
        body2: {
          fontSize: pxToRem(14),
        },
        subtitle1: {
          fontWeight: 600,
          letterSpacing: pxToRem(0.12),
        },
        subtitle2: {
          fontWeight: 600,
        },
      },
      components: {
        MuiAccordion: {
          styleOverrides: {
            root: {
              background: alpha(secondaryColors.light, 0.08),
              boxShadow: "none",
              borderRadius: 0,
              "&:before": {
                display: "none",
              },
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              "& .MuiListItemText-primary": {
                fontSize: pxToRem(16),
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                color: secondaryColors.main,
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: primaryColors.main,
              cursor: "pointer",
              "&:hover, &:visited, &:active, &:focus": {
                color: primaryColors.main,
              },
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              "&.MuiAlert-standard": {
                color: textPrimary,
              },
              "&.MuiAlert-standard > .MuiAlert-icon": {
                color: "unset",
              },
              "&.MuiAlert-standardSuccess": {
                backgroundColor: blendHexColorsToRgb(
                  successColors.main,
                  background.default,
                  0.7
                ),
              },
              "&.MuiAlert-standardError": {
                backgroundColor: blendHexColorsToRgb(
                  errorColors.main,
                  background.default,
                  0.7
                ),
              },
              "&.MuiAlert-standardWarning": {
                backgroundColor: blendHexColorsToRgb(
                  warningColors.main,
                  background.default,
                  0.7
                ),
              },
              "&.MuiAlert-standardInfo": {
                backgroundColor: blendHexColorsToRgb(
                  infoColors.main,
                  background.default,
                  0.7
                ),
              },
            },
          },
        },
        MuiAlertTitle: {
          styleOverrides: {
            root: {
              fontWeight: 600,
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "& > .MuiOutlinedInput-notchedOutline": {
                borderColor: alpha(textPrimary, 0.7),
              },
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontWeight: 600,
            },
          },
        },

        MuiCssBaseline: {
          styleOverrides: `
          @font-face {
            font-family: 'Malarenergi Sans';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: url('${baseUrl}/fonts/MalarenergiSans-Light.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Malarenergi Sans';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: url('${baseUrl}/fonts/MalarenergiSans-Regular.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Malarenergi Sans';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: url('${baseUrl}/fonts/MalarenergiSans-Bold.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Malarenergi Sans';
            font-style: normal;
            font-display: swap;
            font-weight: 800;
            src: url('${baseUrl}/fonts/MalarenergiSans-ExtraBold.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Malarenergi Sans';
            font-style: italic;
            font-display: swap;
            font-weight: 400;
            src: url('${baseUrl}/fonts/MalarenergiSans-Italic.woff2') format('woff2');
          }
          .vngage-banner {
            position: fixed !important;
            z-index: 1000 !important;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 1000px ${hexToRgba(
              primaryColors.main,
              0.1
            )} inset !important;
            -webkit-text-fill-color: ${textPrimary} !important;
          }
          a {
            color: inherit;
            text-decoration: none;
          }
          `,
        },
      },
    },
    svSE,
    dateSvSE
  );
  return theme;
};

export const darkModeColors = createTheme(true).palette;
export const lightModeColors = createTheme(false).palette;
