import {
  camelCase,
  find,
  flatMap,
  get,
  groupBy,
  includes,
  last,
  map,
  reduce,
  size,
  split,
} from 'lodash';
import colors from '@/assets/styles/colors.module.scss';

export const getColorFromString = (color) => {
  return colors[`${camelCase(color)}`];
};

export const nackaUser = (customer) => {
  const customerAccount = getCustomerAccount(customer);
  return get(customerAccount, 'sourceSystem.id') === 1;
};
export const getCustomerAccount = (customer, index = 0) => {
  return get(customer, ['customerAccounts', index]);
};
export const userIsCustomer = (customer) =>
  customer
    ? customer.status
      ? customer.status.id === 1
      : get(getCustomerAccount(customer), 'status.id') === 1
    : false;
export const userHasFlexAccount = (customer) =>
  get(customer, 'paymentInfo.flexAccount');
export const userHasDirectDebit = (customer) =>
  get(customer, 'paymentInfo.directDebit');
export const userIsIndividual = (customer) =>
  get(customer, 'legalPart.id') === 1;
export const userIsActive = (customer) =>
  get(customer, 'status.id') === 1 ||
  get(customer, 'customerAccounts[0].status.id') === 1;
export const userIsInactive = (customer) =>
  get(customer, 'status.id') === 2 ||
  get(customer, 'customerAccounts[0].status.id') === 2;
export const userIsUpcoming = (customer) =>
  get(customer, 'status.id') === 3 ||
  get(customer, 'customerAccounts[0].status.id') === 3;
/**
 * Checks if the customer has effekttariff on any of its contracts on any of its facilities.
 *
 * TODO: This should probably be removed or reworked when all customers have effekttariff.
 * @returns {boolean} true if the customer has effekttariff, false otherwise.
 */
export const userHasEffekttariff = (customer) =>
  customer?.facilities?.some((facility) =>
    facility.contracts?.find(
      (contract) => contract.productNumber === 'EL SÄK EFF',
    ),
  );

export const userHasFlowCharge = (customer) =>
  !isProductionEnvironment() && // TODO WHEN FLOW CHARGE PAGE IS READY FOR RELEASE: Remove this line
  (userHasFJVEffekttariff(customer) ||
    customer?.facilities.some((facility) =>
      facility.contracts?.find((contract) =>
        [
          'INDUSTRI H',
          'INDUSTRI V',
          'INDUSTRI V GULD 10',
          'INDUSTRI S',
        ].includes(contract.productNumber),
      ),
    ));

export const userHasFJVEffekttariff = (customer) =>
  customer?.facilities?.some((facility) =>
    facility.meteringPoints?.find(
      (meteringPoint) => meteringPoint.attributes?.basePower > 0,
    ),
  );

export const hasProduction = (infraService) => {
  return (
    infraService?.meteringPointTypes?.find(
      (meteringPointType) => meteringPointType.name === 'PRODUCTION',
    ) !== undefined
  );
};
export const facilityHasProduction = (facility) => {
  const infraService = getInfraServiceFromFacility(facility, 'EL');
  return (
    infraService?.meteringPointTypes?.find(
      (meteringPointType) => meteringPointType.name === 'PRODUCTION',
    ) !== undefined
  );
};
export const hasUMUtility = (selectedProperty) => {
  return selectedProperty?.utilities?.find((u) => u?.name?.indexOf('UM') > -1);
};
export const getFlexInfraService = (selectedProperty) => {
  if (
    selectedProperty.title !== 'Alla adresser' &&
    selectedProperty.contracts?.find((contract) =>
      contract.productNumber?.includes('FLEX'),
    )
  ) {
    const flexInfraService = selectedProperty.infraServices.find(
      (infraService) =>
        infraService.name === 'EL' &&
        infraService.resolutionType.name === 'HOUR' &&
        selectedProperty.contracts?.find(
          (contract) =>
            contract.utilityType.name === 'ELEXT' &&
            contract.productNumber.includes('FLEX'),
        ),
    );
    return flexInfraService;
  }
  return undefined;
};

export const customerIsMixed = (customer) =>
  get(customer, 'customerType.id') === 100;
export const customerIsCompanyAndLoggedInCompany = (authProfile, customer) =>
  includes(authProfile.amr, 'Company') &&
  get(customer, 'customerType.id') === 2;
export const customerIsCompany = (customer) =>
  customerIsMixed(customer)
    ? customer.customerAccounts.find(
        (account) => account.customerAccountId === customer.customerAccountId,
      )?.legalPart.id === 2
    : get(customer, 'customerType.id') === 2;
export const loggedInAsCompany = (authProfile) =>
  includes(authProfile.amr, 'Company');
export const userHasMultipleCustomerNumbers = (customer) =>
  size(customer.customerAccounts.filter((account) => account.status.id === 1)) >
  1;
export const getMeteringPointByUtilityTypeId = (meteringPoints, utilityId) =>
  find(meteringPoints, (m) => m.utilityType.id === utilityId);
export const getMeteringPointByMeteringPointId = (
  facilities,
  meteringPointId,
) => {
  let meteringPoint;
  let foundMeteringPoint = false;

  facilities?.forEach((currentFacility) => {
    if (foundMeteringPoint) {
      return;
    }

    currentFacility?.meteringPoints?.forEach((mp) => {
      if (mp?.meteringPointId === meteringPointId && mp?.meterNumber !== '') {
        meteringPoint = mp;
        foundMeteringPoint = true;
      } else if (
        mp.meteringPointId === meteringPointId &&
        meteringPoint === undefined
      ) {
        meteringPoint = mp;
      }
      if (foundMeteringPoint) {
        return;
      }
    });
  });

  return meteringPoint;
};
export const getMeteringPointContractsByMeteringPointId = (
  facilities,
  meteringPointId,
) => {
  let meteringPointContracts = [];

  facilities?.forEach((currentFacility) => {
    currentFacility?.contracts?.forEach((contract) => {
      if (contract?.referenceId === meteringPointId) {
        meteringPointContracts?.push(contract);
      }
    });
  });

  return meteringPointContracts;
};

export const splitWithHyphen = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  return (
    str?.slice(0, str?.length / 2) + '\u00AD' + str?.slice(str?.length / 2)
  );
};

export const getInfraServiceFromFacility = (facility, infraServiceName) =>
  find(facility?.infraServices, (s) => s?.name === infraServiceName);
export const infraServiceHasHourPrice = (infraService) => {
  const obj = reduce(
    infraService.meteringPointInfo,
    (o, key) => ({ ...o, [key.meteringPointType.name]: key.hourPrice }),
    {},
  );
  obj.diff =
    size(groupBy(flatMap(infraService.meteringPointInfo, (m) => m.hourPrice))) >
    1;
  return obj;
};

export const isReadingTypeManual = (infraService) =>
  infraService?.readingType?.name === 'MANUAL';
export const propertyHasFjvServiceAgreement = (property) =>
  property.services
    ? !!find(property.services, (s) => get(s, 'utilityType.id') === 7)
    : false;
export const getResolutionTypeIdFromInfraService = (infraService, type) =>
  get(
    find(
      infraService.meteringPointInfo,
      (m) => m.meteringPointType.name === type,
    ),
    'resolutionType.id',
  );

export const isPropertyFacility = (property) => !!property.facilityKey;
export const isPropertyAllFacilities = (property) =>
  property.groupId === '00000000-0000-0000-0000-000000000000';
export const groupHasSubGroups = (group) => size(group.subGroups) > 0;

export const toFixed = (value, decimals = 2, string = true, force = false) => {
  if (value < 1 && decimals < 3 && value > 0) {
    decimals = 2;
  }
  let ret =
    Math.round((value + 0.00001) * Math.pow(10, decimals)) /
    Math.pow(10, decimals);
  ret = ret % 1 !== 0 || force ? ret.toFixed(decimals) : ret;
  if (string) {
    ret = ret.toString();
    if (ret > 1000) {
      const str = reverseString(ret);
      const arr = str.match(/.{1,3}/g);
      ret = reverseString(arr.join(' '));
      // ret = ret.substring(0, 3) + ' ' + ret.substring(3)
    }
    ret = ret.replace(' .', ',');
    return ret;
  }

  return parseFloat(ret);
};

export const reverseString = (str) => {
  // Step 1. Use the split() method to return a new array
  var splitString = str.split(''); // var splitString = "hello".split("");
  // ["h", "e", "l", "l", "o"]

  // Step 2. Use the reverse() method to reverse the new created array
  var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
  // ["o", "l", "l", "e", "h"]

  // Step 3. Use the join() method to join all elements of the array into a string
  var joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
  // "olleh"

  // Step 4. Return the reversed string
  return joinArray; // "olleh"
};

export const formatUnit = (unitString) => {
  switch (unitString) {
    case 'MWH':
      return 'MWh';
    case 'KWH':
      return 'kWh';
    default:
      return unitString;
  }
};
export const kronorOrOre = (valueInKr, withSuffix = true) => {
  const ret =
    Math.abs(toFixed(valueInKr, 4)) < 1
      ? `${toFixed(valueInKr * 100, 2)}${withSuffix ? ' öre' : ''}`
      : `${toFixed(valueInKr, 2)}${withSuffix ? ' kr' : ''}`;
  return withSuffix ? ret : parseInt(ret);
};

export const isColorHex = (color) => {
  return color && color.toString().charAt(0) === '#';
};

export const setupQuery = (filtered) => {
  const query = reduce(
    filtered,
    function (result, value) {
      const key = last(split(value.id, '.'));
      result[key] = value.value;
      return result;
    },
    {},
  );
  return query;
};

export const setupSort = (sorted) => {
  const sort = map(sorted, (item) => {
    const key = last(split(item.id, '.'));
    return {
      field: key,
      direction: item.desc ? 'desc' : 'asc',
    };
  });

  return sort;
};

export const download = (data, filename, type) => {
  const file = new Blob([data], { type: type });
  // IE10+
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const getFrekvens = (reportOptions, value) => {
  value = value || reportOptions.rollingTimeSpanValue;
  if (reportOptions.rollingTimeSpanValue) {
    const prefix = value === 8 ? 'v' : 'mån';
    return `Rullande ${value}${prefix}`;
  }
  return reportOptions.fromDate
    ? `${reportOptions.fromDate} - ${reportOptions.toDate}`
    : '';
};

export const getMessageStatusColorFromKey = (key) => {
  switch (key) {
    case 1:
      return 'success.main';
    case 3:
      return 'info.main';
    case 4:
      return 'info.main';
    case 5:
      return 'error';
    default:
      return 'primary';
  }
};

export const addToWindowObject = (name, objectToAdd) => {
  if (window && !window[name]) {
    window[name] = objectToAdd;
  }
};

/**
 * Returns a date where where the given amount of business days have passed since the given date.
 * @param {Date} date reference date.
 * @param {number} businessDaysToAdd number of business days to add to the reference date.
 * @returns the date where the given amount of business days have passed.
 */
export const addBusinessDays = (date, businessDaysToAdd) => {
  // clone the date to avoid unexpected mutation
  const dateClone = new Date(date.getTime());

  if (dateClone.getDay() === 6) dateClone.setDate(dateClone.getDate() + 1);
  for (let i = 0; i < businessDaysToAdd; i++) {
    dateClone.setDate(dateClone.getDate() + 1);
    if (dateClone.getDay() === 6) dateClone.setDate(dateClone.getDate() + 2);
  }
  return dateClone;
};

export const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const pxToRem = (px) => `${px / 16}rem`;

/**
 * Returns a formatted string of the number with swedish locale.
 * @param {Number} number the number to format.
 * @param {Number} decimals the number of decimals to show.
 * @param {Boolean} trailingZeros if true, trailing zeros will be shown.
 */
export const formatNumber = (number, decimals = 2, trailingZeros = false) => {
  if (!number) return number;
  return number.toLocaleString('sv-SE', {
    minimumFractionDigits: trailingZeros ? decimals : 0,
    maximumFractionDigits: decimals,
  });
};

/**
 * Returns a formatted and conditional rounded string of the number with swedish locale
 * @param {Number} number the number to format.
 * @param {Number} decimals the number of decimals to show.
 * @param {Number} conditionalLevel conditional level to round the number.
 * @param {Boolean} formatted if true, the number will be formatted.
 */
export const conditionalRound = (
  number,
  decimals = 2,
  conditionalLevel = 10,
  formatted = true,
) => {
  if (number > conditionalLevel || number < -conditionalLevel) {
    return formatted ? formatNumber(Math.round(number)) : Math.round(number);
  }
  return formatted ? formatNumber(number, decimals) : number?.toFixed(decimals);
};

export const isExternalUrl = (url) => {
  return url && url.startsWith('http');
};

export const localstorageIsEnabled = () => {
  try {
    localStorage.setItem('testLocalstorage', 'test');
    localStorage.removeItem('testLocalstorage');
    return true;
  } catch (evt) {
    console.error('Localstorage is disabled', evt);
    return false;
  }
};

export const isProductionEnvironment = () => {
  return window.location.href.indexOf('mitt.malarenergi') > -1;
};
