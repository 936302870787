import { Theme, alpha as alphaHexToRgba, hexToRgb } from "@mui/material";

/**
 * Converts a hex color to rgba.
 * @param hex - hex color code
 * @param alpha - alpha value
 * @returns rgba color code
 */
export const hexToRgba = (hex: string, alpha: number) =>
  alphaHexToRgba(hex, alpha);

/**
 * Blends two hex colors together to an rgb string.
 * @param hex1 - first hex color code
 * @param hex2 - second hex color code
 * @param ratio - ratio between the first and second color. 0 is the first color, 1 is the second color.
 * @returns blended colors as rgb
 */
export const blendHexColorsToRgb = (hex1: string, hex2: string, ratio = 0.5) =>
  blendRgbColors(hexToRgb(hex1), hexToRgb(hex2), ratio);

/**
 * Blends two rgb colors together to an rgb string.
 * @param rgb1 - first rgb color code
 * @param rgb2 - second rgb color code
 * @param ratio - ratio between the first and second color. 0 is the first color, 1 is the second color.
 * @returns blended colors as rgb
 */
export const blendRgbColors = (rgb1: string, rgb2: string, ratio = 0.5) => {
  const rgb1Values = rgb1.match(/\d+/g)?.map(Number);
  const rgb2Values = rgb2.match(/\d+/g)?.map(Number);
  if (
    !rgb1Values ||
    !rgb2Values ||
    rgb1Values.length !== 3 ||
    rgb2Values.length !== 3
  ) {
    return "";
  }

  const [r1, g1, b1] = rgb1Values;
  const [r2, g2, b2] = rgb2Values;

  const r = Math.round(r1 + (r2 - r1) * ratio);
  const g = Math.round(g1 + (g2 - g1) * ratio);
  const b = Math.round(b1 + (b2 - b1) * ratio);

  return `rgb(${r}, ${g}, ${b})`;
};

export const lightPrimaryShades4p = (theme: Theme) => {
  return alphaHexToRgba(theme.palette.secondary.main, 0.04); // lightPrimaryShades(theme, 0.04);
};

export const lightPrimaryShades8p = (theme: Theme) => {
  return alphaHexToRgba(theme.palette.secondary.main, 0.08);
};
