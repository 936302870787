import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { apiClient } from '../../app/axios.config';
import { getSelectedCustomer } from '../../app/slices/userSlice';
import _, { find, filter } from 'lodash';

const distanceHeatingServiceSlice = createSlice({
  name: 'fjv-service',
  initialState: {
    activity: null,
    confirmedBookings: [],
    availableOccasions: {},
  },
  reducers: {
    setAvailableOccasions: (state, action) => {
      state.availableOccasions = action.payload;
    },
    setActivity: (state, action) => {
      state.activity = action.payload;
    },
    setConfirmedBookings: (state, action) => {
      state.confirmedBookings = action.payload;
    },
    setCancelBooking: (state, action) => {
      state.confirmedBookings = filter(
        state.confirmedBookings,
        (booking) => booking.id !== action.payload.bookingId,
      );
    },
  },
});

export const {
  setAvailableOccasions,
  setActivity,
  setConfirmedBookings,
  setCancelBooking,
} = distanceHeatingServiceSlice.actions;

export const fetchAvailableOccasions =
  (activityIdNum, countyCode, from = dayjs()) =>
  (dispatch) => {
    countyCode =
      parseInt(activityIdNum) === 8 || parseInt(activityIdNum) === 17
        ? null
        : countyCode;

    return apiClient
      .get(
        `bookings/activities/${activityIdNum}/occasions?fromDate=${from.format(
          'YYYY-MM-DD',
        )}${countyCode ? `&countyCode=${countyCode}` : ''}`,
      )
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          dispatch(setAvailableOccasions(response?.data?.data));
        } else {
          const aYearLater = dayjs().add(1, 'year');
          const diff = aYearLater.diff(from.add(3, 'month'), 'month');
          return diff >= 0
            ? dispatch(fetchAvailableOccasions(activityIdNum, countyCode, from))
            : dispatch(setAvailableOccasions(response?.data?.data));
        }
      });
  };

export const fetchBookings = () => (dispatch, getState) => {
  return apiClient
    .get('bookings/activities')
    .then((response) => response?.data?.data)
    .then((activities) => {
      const activity = find(activities, {
        id: Number(import.meta.env.VITE_APP_FJV_ACTIVITY_ID_NUM),
      });
      dispatch(setActivity(activity));
      return activity.workOrderTypeId;
    })
    .then((workOrderTypeId) =>
      dispatch(fetchConfirmedBookings(workOrderTypeId)),
    );
};

export const fetchConfirmedBookings =
  (workOrderTypeId) => (dispatch, getState) => {
    const selectedCustomer = getSelectedCustomer(getState());
    return apiClient
      .get(`bookings/customer/${selectedCustomer.customerNumber}`)
      .then((response) => response?.data?.data)
      .then((confirmedBookings) =>
        dispatch(
          setConfirmedBookings(
            _(confirmedBookings)
              .filter({ activityId: workOrderTypeId })
              .orderBy(['appointmentDate'], ['desc'])
              .value(),
          ),
        ),
      )
      .catch((error) =>
        console.error('failed to fetch confirmed bookings', error),
      );
  };

export const cancelBooking = (bookingId) => (dispatch) => {
  return apiClient
    .delete(`bookings/${bookingId}`)
    .then((response) => response?.data?.data?.[0])
    .then((res) => {
      if (res.success) {
        return dispatch(setCancelBooking({ bookingId }));
      } else {
        return { errorMessage: res.errorText };
      }
    });
};

export default distanceHeatingServiceSlice.reducer;
