import { Tab as MuiTab, TabProps, styled } from "@mui/material";

const StyledTab = styled(MuiTab)`
  text-transform: none;
`;

const Tab = (props: TabProps) => {
  return <StyledTab {...props} />;
};

export default Tab;
