import { useEffect, useState } from 'react';
import { Box, Typography } from 'ui/mui';

const StorageDetection = () => {
  const [localStorageEnabled, setLocalStorageEnabled] = useState(true);
  const [sessionStorageEnabled, setSessionStorageEnabled] = useState(true);

  useEffect(() => {
    const checkLocalStorage = () => {
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
      } catch (e) {
        setLocalStorageEnabled(false);
      }
    };

    const checkSessionStorage = () => {
      try {
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
      } catch (e) {
        setSessionStorageEnabled(false);
      }
    };

    checkLocalStorage();
    checkSessionStorage();
  }, []);

  return (
    <div>
      {(!localStorageEnabled || !sessionStorageEnabled) && (
        <Box>
          <Typography>
            Lokal lagring (
            {!localStorageEnabled && !sessionStorageEnabled
              ? 'localStorage och sessionStorage'
              : !localStorageEnabled
              ? 'localStorage'
              : 'sessionStorage'}
            ) är avstängd i webbläsaren.
          </Typography>
          <Typography>
            Du behöber aktivera lagring i webbläsaren för att använda denna
            webbplats.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default StorageDetection;
