import { getCustomerType, trackingLogin } from '@/helpers/trackingHelper';
import { Account, Customer } from '@/types/Customer';
import { State } from '@/types/State';
import { get, includes } from 'lodash';
import { User } from 'oidc-client';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import PageLoader from 'ui/components/PageLoader';
import {
  fetchAccount,
  fetchAccountCustomerId,
  fetchCustomer,
  fetchSelectedCustomerFacilities,
  setNonCustomer,
  setSelectedProperty,
} from '../../app/slices/userSlice';
import userManager from '../../app/userManager';
import Error from '../../components/error/Error';
import NoUserError from '../../components/error/NoUserError';
import {
  customerIsMixed,
  userHasMultipleCustomerNumbers,
  userIsActive,
  userIsIndividual,
  userIsUpcoming,
} from '../../helpers/utils';
import { fetchSpecialSeries } from '../infra-service/specialSeriesSlice';
import localforage from 'localforage';

const Callback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [hasNoUser, setHasNoUser] = useState(false);
  const auth = useSelector((state: State) => state?.auth);
  const shouldRedirectFornya = !!localStorage.getItem('fornya');
  const [confirmAccount, setConfirmAccount] = useState<string | false>(false);

  useEffect(() => {
    localforage.getItem('bekrafta_konto').then((code) => {
      if (code) {
        setConfirmAccount(code as string);
      }
    });
  }, []);

  useEffect(() => {
    if (auth?.user !== null) {
      setHasError(false);
    }
    const tiemoutId = setTimeout(() => {
      setHasNoUser(true);
    }, 40000);

    return () => clearTimeout(tiemoutId);
  }, [auth]);

  const isCompanyNotAssigned = useCallback(
    (user: User) => {
      // Check if customer has role CompanyNotAssigned, redirect if true

      if (
        get(user, 'profile.role') &&
        user.profile.role.indexOf('CompanyNotAssigned') > -1 &&
        auth?.user &&
        get(auth, 'user.profile.amr') &&
        auth.user.profile.amr.indexOf('Company') > -1 &&
        !confirmAccount
      ) {
        navigate('/foretag/skapa-anvandare');
        return false;
      }

      return true;
    },
    [auth, confirmAccount, navigate],
  );

  const redirectToFornya = useCallback((customer: Customer) => {
    const isCompany = !userIsIndividual(customer);
    const customerNumber = customer.customerNumber;
    const globalWindow = window as any;
    const token = globalWindow?.token;
    const redirectUrl = `${
      import.meta.env.VITE_APP_OLD_ME_URL
    }?customerNumber=${customerNumber}&token=${token}&returnurl=/${
      isCompany ? 'foretag/' : ''
    }fornya-elavtal`;
    localStorage.removeItem('fornya');
    window.location.href = redirectUrl;
  }, []);

  const successCallback = useCallback(
    (user: User) => {
      dispatch(fetchAccount() as any)
        .then((account: Account) => {
          if (!isCompanyNotAssigned(user)) {
            return;
          }

          if (includes(user.profile.role, 'NonCustomer')) {
            trackingLogin('NonCustomer');
            dispatch(setNonCustomer(false) as any).then(() => {
              if (confirmAccount) {
                navigate(`/bekrafta_konto/${confirmAccount}`);
              } else if (get(account, 'payload.hasLinkedAccounts')) {
                navigate('/switch-user');
              } else {
                navigate('/non-customer');
              }
            });
            return;
          }
          const customerId = get(user, 'profile.mecid');

          if (!customerId) {
            navigate('/signout');
            return;
          }

          dispatch(fetchCustomer(customerId) as any)
            .then((customer: Customer) => {
              if (customer === undefined) {
                setHasNoUser(true);
                return;
              }

              try {
                dispatch(fetchSelectedCustomerFacilities() as any);
                if (customer?.customerId) {
                  dispatch(
                    fetchSpecialSeries('' + customer?.customerId) as any,
                  );
                  trackingLogin(getCustomerType(customer), customer);
                }
              } catch (error) {
                console.log('Tracking login', error);
              }
              if (!userIsActive(customer) && !userIsUpcoming(customer)) {
                dispatch(
                  setSelectedProperty({
                    infraServices: [],
                  }),
                );
                if (confirmAccount) {
                  navigate(`/bekrafta_konto/${confirmAccount}`);
                } else if (get(account, 'payload.hasLinkedAccounts')) {
                  navigate('/switch-user');
                } else {
                  navigate('/non-customer');
                }

                return;
              }

              if (
                ((customerIsMixed(customer) &&
                  userHasMultipleCustomerNumbers(customer)) ||
                  get(account, 'payload.hasLinkedAccounts')) &&
                !confirmAccount
              ) {
                navigate('/switch-user?showModal=true&autoLogin=true');
                return;
              }

              if (shouldRedirectFornya) {
                redirectToFornya(customer);
                return;
              }

              dispatch(fetchAccountCustomerId(customerId) as any);

              if (localStorage.getItem('redirectUri')) {
                const redirectUri = localStorage.getItem('redirectUri');
                localStorage.removeItem('redirectUri');
                redirectUri && navigate(redirectUri);
                return;
              }

              if (confirmAccount) {
                navigate(`/bekrafta_konto/${confirmAccount}`);
              } else {
                navigate('/');
              }
            })
            .catch((error: Error) => {
              console.error('fetchCustomer', error, user);
              setHasError(true);
              throw error;
            });
        })
        .catch((error: Error) => {
          console.error('fetchAccount', error, user);
          setHasError(true);
          throw error;
        });
    },
    [
      dispatch,
      isCompanyNotAssigned,
      confirmAccount,
      navigate,
      shouldRedirectFornya,
      redirectToFornya,
    ],
  );

  const errorCallback = useCallback(
    (error: Error) => {
      if (
        hasError ||
        auth?.isLoadingUser ||
        error?.message === 'No matching state found in storage' ||
        error?.message === 'Network Error'
      ) {
        return;
      }

      if (error?.message === 'No state in response') {
        navigate('/');
        return;
      }

      console.error('errorCallback', error?.message);
      setHasError(true);
    },
    [auth?.isLoadingUser, hasError, navigate],
  );

  return (
    //@ts-ignore
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      {hasError ? (
        <Error />
      ) : hasNoUser ? (
        <NoUserError />
      ) : (
        <PageLoader title='Hämtar dina uppgifter...' />
      )}
    </CallbackComponent>
  );
};

export default Callback;
