import specialSeriesReducer from '@/pages/infra-service/specialSeriesSlice';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import contractReducer from '../features/contracts/contractSlice';
import routesReducer from '../features/routes/routesSlice';
import sidebarReducer from '../layout/sidebar/sidebarSlice';
import distanceHeatingContractReducer from '../pages/distance-heating-contract/distanceHeatingContractSlice';
import distanceHeatingServiceReducer from '../pages/distance-heating-service/distanceHeatingServiceSlice';
import meterSwitchReducer from '../pages/electricity-meter-switch/meterSwitchSlice';
import messagesReducer from '../pages/messages/messagesSlice';
import reportsReducer from '../pages/reports/reportsSlice';
import interruptionsReducer from './slices/interruptionSlice';
import userReducer from './slices/userSlice';

import * as Sentry from '@sentry/browser';
import localforage from 'localforage';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import authMiddleware from './middleware/authMiddleware';

const ignoreErrors = [
  /ErrorResponse.*login_required/,
  /Error.*invalid_grant/,
  /Error.*Network Error/,
  /Error.*Frame window timed out/,
];

// Initiera Sentry
if (window?.location?.href.indexOf('localhost') === -1) {
  Sentry.init({
    environment: window?.location?.hostname,
    dsn: 'https://23d1e0e22f1f4afeb65abfb0566211de@o1140508.ingest.sentry.io/6208811',
    release: `mitt-malarenergi@${import.meta.env.VITE_APP_ME_VERSION}`,
    ignoreErrors,
  });
}

// Skapa timestamp-transform för persist
const setTimestamp = createTransform(
  (inboundState) => {
    if (typeof inboundState === 'object' && inboundState !== null) {
      return { ...inboundState, _timestamp: Date.now() };
    }
    return inboundState;
  },
  (outboundState: any) => {
    const daysBeforePurge = 1;
    if (
      outboundState?._timestamp &&
      Date.now() - outboundState?._timestamp >
        daysBeforePurge * 24 * 60 * 60 * 1000
    ) {
      return undefined;
    }

    return outboundState;
  },
  {
    whitelist: [
      'user',
      'contracts',
      'interruptions',
      'forecasts',
      'facilities',
      'fjvContract',
      'fjvService',
      'fjvSimulation',
      'reports',
      'messages',
    ],
  },
);

export const persistConfig = {
  key: `me_${import.meta.env.VITE_APP_ME_ENV}`,
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['router', 'electricityMeterSwitch', 'drainMeterReadings'],
  transforms: [setTimestamp],
};

const uiReducer = combineReducers({
  sidebar: sidebarReducer,
});

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: oidcReducer,
    ui: uiReducer,
    user: userReducer,
    routes: routesReducer,
    contracts: contractReducer,
    interruptions: interruptionsReducer,
    electricityMeterSwitch: meterSwitchReducer,
    fjvContract: distanceHeatingContractReducer,
    fjvService: distanceHeatingServiceReducer,
    reports: reportsReducer,
    messages: messagesReducer,
    specialSeries: specialSeriesReducer,
  }) as any,
);

const rootReducer = (state: any, action: any) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined;
  }
  return persistedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: null,
      },
      serializableCheck: false,
      immutableCheck: false,
    }).concat([authMiddleware]),
});

export const persistor = persistStore(store);

export default store;
