import ProfileMenu from '@/features/profile-menu/ProfileMenu';
import { isProductionEnvironment } from '@/helpers/utils';
import { State } from '@/types/State';
import _, { map } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Toolbar, Typography } from 'ui/mui';
import pjson from '../../../package.json';
import { bottomNavigationHeight } from '../Layout';
import PageHeading from '../page/PageHeading';
import SidebarMenu from './components/SidebarMenu';
import { setMenus } from './sidebarSlice';

type SidebarProps = {
  openMobileMenu: boolean;
  setOpenMobileMenu: (open: boolean) => void;
  openMobileProfile: boolean;
  setOpenMobileProfile: (open: boolean) => void;
  drawerWidth?: number;
};

const SidebarContent = () => {
  const dispatch = useDispatch();
  const { menus } = useSelector((state: State) => state.ui.sidebar);
  const { selectedProperty } = useSelector((state: State) => state.user);
  const { routes } = useSelector((state: State) => state.routes);
  const setupMenus = useCallback(() => {
    const mMenus: any = {
      overview: {
        menuTitle: '',
        menuItems: [],
      },
      services: {
        menuTitle: 'Tjänster',
        menuItems: [],
      },
      tools: {
        menuTitle: 'Verktyg',
        menuItems: [],
      },
    };

    if (!routes) {
      return mMenus;
    }

    const mRoutes = [...routes];

    const { infraServices } = selectedProperty;

    _.forEach(
      _(mRoutes)
        .pickBy((r) => {
          if (r.section && !r.infraService && !r.hidden) {
            return true;
          } else {
            return (
              !!_.find(infraServices, { name: r.infraService }) && !r.hidden
            );
          }
        })
        .values()
        .uniqBy('path')
        .orderBy(['title'], ['asc'])
        .value(),
      (route) => {
        const childRoutes = _.filter(route.childRoutes, (cr) => !cr.hidden);
        const newRoute = { ...route };
        newRoute.childRoutes = childRoutes;
        mMenus[route.section].menuItems.push(newRoute);
      },
    );

    dispatch(setMenus(mMenus));
  }, [dispatch, routes, selectedProperty]);

  useEffect(() => {
    routes && setupMenus();
  }, [routes, setupMenus]);
  return (
    <>
      {menus &&
        map(
          {
            services: {
              menuItems: [
                ...menus.overview.menuItems,
                ...menus.services.menuItems,
                ...menus.tools.menuItems,
              ],
            },
          },
          (menu, index) => (
            <SidebarMenu items={menu.menuItems} key={`menu_${index}`} />
          ),
        )}
      {isProductionEnvironment() ? null : (
        <Typography sx={{ ml: 2 }} variant='caption'>
          Version: {import.meta.env.VITE_APP_ME_VERSION}
        </Typography>
      )}
    </>
  );
};

const Sidebar = ({
  openMobileMenu,
  setOpenMobileMenu,
  openMobileProfile,
  setOpenMobileProfile,
  drawerWidth,
}: SidebarProps) => {
  const location = useLocation();

  useEffect(() => {
    setOpenMobileMenu(false);
    setOpenMobileProfile(false);
  }, [location, setOpenMobileMenu, setOpenMobileProfile]);

  return (
    <Box>
      {/* mobile profile menu */}
      <Drawer
        open={openMobileProfile}
        onClose={() => {
          setOpenMobileProfile(false);
        }}
        anchor='bottom'
        ModalProps={{
          keepMounted: false,
          onClose: () => setOpenMobileProfile(false),
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          zIndex: 999,
        }}
        variant='temporary'
        PaperProps={{
          sx: {
            backgroundImage: 'none',
            minHeight: '100%',
          },
        }}
      >
        <Toolbar />

        <ProfileMenu
          mobileOpen={openMobileProfile}
          setMobileOpen={setOpenMobileProfile}
        />

        <Toolbar
          sx={{
            height: `${bottomNavigationHeight}px`,
            display: { xs: 'block', md: 'none' },
          }}
        />
      </Drawer>
      {/* mobile */}
      <Drawer
        open={openMobileMenu}
        anchor='bottom'
        onClose={() => {
          setOpenMobileMenu(false);
        }}
        ModalProps={{
          keepMounted: false,
          onClose: () => setOpenMobileMenu(false),
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          zIndex: 999,
        }}
        variant='temporary'
        PaperProps={{
          sx: {
            backgroundImage: 'none',
            minHeight: '100%',
          },
        }}
      >
        <>
          <Box sx={{ pt: 4, pl: 2 }}>
            <PageHeading
              route={{
                title: 'Meny',
                pageHeaderOptions: {
                  hide: false,
                  propertyText: '',
                  title: 'Meny',
                },
              }}
              component='span'
            />
          </Box>
          <SidebarContent />
          <Toolbar sx={{ height: `${bottomNavigationHeight}px` }} />
        </>
      </Drawer>
      {/* desktop */}
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: 'none',
          },
        }}
        variant='permanent'
      >
        <>
          <ProfileMenu />
          <SidebarContent />
        </>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
