import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../../app/axios.config';

const meterSwitchSlice = createSlice({
  name: 'meter-switch',
  initialState: {
    bookedTimes: [],
    availableOccasions: null,
  },
  reducers: {
    setBookedTimes: (state, action) => {
      state.bookedTimes = action.payload;
    },
    setAvailableOccasions: (state, action) => {
      if (state.availableOccasions == null) {
        state.availableOccasions = Object.assign({}, action.payload);
      } else {
        state.availableOccasions = Object.assign(
          {},
          state.availableOccasions,
          action.payload,
        );
      }
    },
  },
});

export const { setBookedTimes, setAvailableOccasions } =
  meterSwitchSlice.actions;

export const fetchEnergySwitchBooking = () => (dispatch: Dispatch) => {
  if (window?.location?.hostname !== 'mitt.malarenergi.se') {
    console.log('not production add mock booking');
    return import('./mock/energyswitchOneItem.json')
      .then((response) => response?.data)
      .then((bookings) => dispatch(setBookedTimes(bookings)));
  }

  return apiClient
    .get('bookings/energyswitch')
    .then((response) => response?.data?.data)
    .then((bookings) => dispatch(setBookedTimes(bookings)));
};

export const fetchEnergySwitchOccasions =
  (meterpointId: string) => (dispatch: Dispatch) => {
    if (window?.location?.hostname !== 'mitt.malarenergi.se') {
      console.log('not production added mock occasions');
      return import('./mock/occasions.json')
        .then((response) => response?.data?.[0])
        .then((occasions) =>
          dispatch(setAvailableOccasions({ [meterpointId]: occasions })),
        );
    }

    return apiClient
      .get('bookings/energyswitch/occasions', {
        params: {
          meterpointId,
        },
      })
      .then((response) => response?.data?.data?.[0])
      .then((occasions) =>
        dispatch(setAvailableOccasions({ [meterpointId]: occasions })),
      )
      .catch(() => {
        dispatch(setAvailableOccasions([]));
      });
  };

export default meterSwitchSlice.reducer;
