import { styled } from "@mui/material";
import { hexToRgba } from "../../utils/color";
import areasConfig from "./areasConfig";

export type ElectricityArea = "SE1" | "SE2" | "SE3" | "SE4";
type AreaMapProps = {
  onChange: (area: ElectricityArea) => void;
  activeArea: ElectricityArea;
};

const MapSvg = styled("svg")`
  width: 100%;
  height: 100%;
  filter: drop-shadow(
    0px 10px 20px
      ${({ theme }) => hexToRgba(theme.palette.secondary.dark, 0.12)}
  );
`;
const MapText = styled("text")`
  font: 40% sans-serif;
  fill: ${({ theme }) => theme.palette.text.primary};
  text-anchor: middle;
`;
const MapPath = styled("path")``;
const MapGroup = styled("g")`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  & > path,
  & > text {
    transition:
      fill 200ms ease-in-out,
      stroke 200ms ease-in-out,
      stroke-width 200ms ease-in-out;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover path,
    &:focus path {
      stroke-width: 0.75;
      fill: ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.3)};
    }
  }
`;

export default function AreaMap({ onChange, activeArea }: AreaMapProps) {
  return (
    <MapSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="70 40 70 150"
      role="group"
    >
      {areasConfig.map((area) => (
        <MapGroup
          key={area.name}
          role="button"
          aria-label={`Välj elområde ${area.name}`}
          aria-pressed={activeArea === area.name}
          data-analytics-id={`area-map-${area.name}`}
          tabIndex={0}
          onClick={() => onChange(area.name as ElectricityArea)}
          onKeyUp={(e) => {
            if (e.code === "Enter") {
              onChange(area.name as ElectricityArea);
            }
          }}
        >
          <MapPath
            {...area.path}
            sx={(theme) => ({
              fill:
                activeArea === area.name
                  ? theme.palette.primary.light + " !important"
                  : theme.palette.background.paper,
              stroke: theme.palette.text.secondary,
              strokeWidth: 0.5,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            })}
          />
          <MapText
            {...area.text}
            sx={(theme) => ({
              fill:
                activeArea === area.name
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary,
            })}
            dy="0.34em"
          >
            {area.name}
          </MapText>
        </MapGroup>
      ))}
    </MapSvg>
  );
}
