import localforage from 'localforage';
import { includes } from 'lodash';
import { purgeStoredState } from 'redux-persist';
import { persistConfig } from '../store';

import { ThunkMiddleware, UnknownAction } from '@reduxjs/toolkit';
import { PersistPartial } from 'redux-persist/es/persistReducer';

const authMiddleware: ThunkMiddleware<PersistPartial, UnknownAction, null> =
  () => (next) => (action) => {
    try {
      const typeArr = [
        'redux-oidc/SESSION_TERMINATED',
        'redux-oidc/USER_EXPIRED',
        'redux-oidc/USER_SIGNED_OUT',
      ];
      if (includes(typeArr, (action as any).type)) {
        localforage.removeItem(`persist:me_${import.meta.env.VITE_APP_ME_ENV}`);
        return purgeStoredState(persistConfig);
      }
      return next(action);
    } catch (err) {
      /* empty */
    }
  };

export default authMiddleware;
