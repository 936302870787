import { Customer } from '@/types/Customer';
import { includes } from 'lodash';
import TagManager from 'react-gtm-module';
import { ThumbsSurveyData } from 'ui';
import { userIsCustomer, userIsIndividual } from './utils';

type UserType = 'Private' | 'Company' | 'NonCustomer';
const appName = 'mitt_me';

const testOrProduction =
  includes(window.location.href, 'mitt.malarenergi') ||
  includes(window.location.href, 'mitt-test.malarenergi');

export const getCustomerType = (customer: Customer) => {
  return userIsIndividual(customer) ? 'Private' : 'Company';
};

export const trackingInit = () => {
  if (!testOrProduction) {
    return;
  }
  const tagManagerArgs = {
    gtmId: 'GTM-KCH4HXV',
  };
  TagManager.initialize(tagManagerArgs);
};

export const trackingLogin = (userType: UserType, customer?: Customer) => {
  if (!testOrProduction) {
    return;
  }
  TagManager.dataLayer({
    dataLayer: {
      event: 'Login',
      step: 'login',
      application: appName,
      userType,
      tjänster: customer?.utilities?.map((u) => u.name).join(','),
    },
  });
};

export const trackingSwitchCustomer = (customer: Customer) => {
  if (!testOrProduction) {
    return;
  }
  const userType = userIsCustomer(customer)
    ? getCustomerType(customer)
    : 'NonCustomer';
  TagManager.dataLayer({
    dataLayer: {
      event: 'Login',
      step: 'change',
      application: appName,
      tjänster: customer?.utilities?.map((u) => u.name).join(','),
      userType,
    },
  });
};

export const trackingSwitchAddress = (info: string) => {
  if (!testOrProduction) {
    return;
  }
  TagManager.dataLayer({
    dataLayer: {
      event: 'me_adresser',
      event_info: info,
      application: appName,
    },
  });
};

export const trackingSimpleEvent = (event: string) => {
  if (!testOrProduction) {
    return;
  }
  TagManager.dataLayer({
    dataLayer: {
      event,
      application: appName,
    },
  });
};

export const trackingSubmitDrainReading = () => {
  trackingSimpleEvent('skicka mätarställning');
};

export const trackingThumbsSurvey = (data: ThumbsSurveyData) => {
  if (!testOrProduction) {
    return;
  }
  TagManager.dataLayer({
    dataLayer: {
      event: 'feedback',
      step: data.thumbs === 'thumbs-up' ? 'positiv' : 'negativ',
      event_info: data.optionalText,
    },
  });
};
