import React from 'react';
import * as Sentry from '@sentry/browser';
import Error from './components/error/Error';

class ErrorWrapper extends React.Component<
  { children: React.ReactNode },
  { error: any }
> {
  state = {
    error: null,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return this.state.error ? <Error /> : this.props.children;
  }
}

export default ErrorWrapper;
