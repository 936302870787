import { List } from 'ui/mui';
import SidebarMenuItem, { SidebarMenuItemProps } from './SidebarMenuItem';

type SidebarMenuProps = {
  items: SidebarMenuItemProps[];
  handleClose?: () => void;
};

const SidebarMenu = ({ items, handleClose }: SidebarMenuProps) => {
  return (
    <List component='nav'>
      {items && items.length > 0
        ? items.map(({ title, path, icon, childRoutes, badgeCount }, index) => (
            <SidebarMenuItem
              key={`${title}_${index}`}
              title={title}
              path={path}
              icon={icon}
              childRoutes={childRoutes}
              badgeCount={badgeCount}
              handleClose={handleClose}
            />
          ))
        : null}
    </List>
  );
};

export default SidebarMenu;
