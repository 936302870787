/**
 * Takes a number representing a size in pixels and converts it to a string of
 * equivalent size in rem.
 * @param px The number of pixels to convert.
 * @returns The equivalent size in rem.
 */
export const pxToRem = (px: number) => `${px / 16}rem`;

/**
 * Returns a formatted metring point id (anläggnings-id).
 * @param meteringPointId "Anläggnings-ID" a string of numbers
 * @returns a string of numbers with a space after every 3rd character
 */
export const displayMeteringPointId = (meteringPointId: string) => {
  // metering point id is a string of only numbers, add a space after every 3rd
  // character to make it easier to read
  return meteringPointId.replace(/(.{3})/g, "$1 ");
};
