import localforage from 'localforage';
import { WebStorageStateStore } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const oidcSettings = {
  authority: import.meta.env.VITE_APP_AUTHORITY,
  client_id: import.meta.env.VITE_APP_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_APP_REDIRECT_URI,
  response_type: 'code',
  scope: import.meta.env.VITE_APP_SCOPE,
  userStore: new WebStorageStateStore({ store: localforage }),
  post_logout_redirect_uri: import.meta.env.VITE_APP_POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: import.meta.env.VITE_APP_SILENT_RENEW_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(oidcSettings);

userManager.startSilentRenew();

userManager.events.addSilentRenewError(async () => {
  history.push('/signout');
});

export default userManager;
