import { includes, last } from 'lodash';
import dayjs from 'dayjs';
import React from 'react';
import CookieBot from 'react-cookiebot';
import { createRoot } from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import {
  PersistQueryClientProvider,
  PersistedClient,
  Persister,
} from '@tanstack/react-query-persist-client';
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import { } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store, { persistor } from './app/store';
import userManager from './app/userManager';
import pjson from '../package.json';
import { BrowserRouter } from 'react-router-dom';
import localforage from 'localforage';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import 'dayjs/locale/sv';
dayjs.locale('sv');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

loadUser(store, userManager);

const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('fornya')) {
  localStorage.setItem('fornya', '1');
}
if (includes(window.location.href, 'bekrafta_konto')) {
  const code = last(window.location.href.split('/'));
  const match = code?.match(
    '^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$',
  );
  if (match && code) {
    localforage.setItem('bekrafta_konto', code);
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 60 * 1000, // 2 hour
    },
  },
});

const createIDBPersister = (idbValidKey: string = 'reactQuery') => {
  return {
    persistClient: async (client: PersistedClient) => {
      await localforage.setItem(idbValidKey, client);
    },
    restoreClient: async () => {
      return await localforage.getItem<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await localforage.removeItem(idbValidKey);
    },
  } as Persister;
};

const persister = createIDBPersister(
  `mitt-malarenergi-${import.meta.env.VITE_APP_ME_ENV}`,
);
const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
if (window.location.pathname === '/silent-renew') {
  userManager.signinSilentCallback().catch((error) => {
    console.error('Error during silent renew:', error);
  });
} else {
  root.render(
    <React.StrictMode>
      {includes(window.location.href, 'mitt.malarenergi') && (
        <CookieBot domainGroupId='82dbc703-1cad-4bf1-8d04-d7477dfd2a26' />
      )}
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister,
          buster: pjson.version,
          // import.meta.env.VITE_APP_ME_ENV === 'localhost'
          //   ? '' + Date.now()
          //   : pjson.version,
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* @ts-ignore */}
            <OidcProvider userManager={userManager} store={store}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </OidcProvider>
          </PersistGate>
        </Provider>
      </PersistQueryClientProvider>
    </React.StrictMode>,
  );
}
