import axios from 'axios';
import _ from 'lodash';
import userManager from './userManager';

const responseInterceptorFulfilled = (response: any) => {
  return response;
};

const responseInterceptorRejected = async (error: any) => {
  if (_.includes(String(error), 'Network Error')) {
    return;
  }

  return Promise.reject(error);
};

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: any = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const interceptorRequestHandler = async (request: any) => {
  const user = await userManager?.getUser();

  if (user && !user.expired) {
    request.headers.Authorization = `Bearer ${user.access_token}`;
    return request;
  } else {
    if (!isRefreshing) {
      isRefreshing = true;
      userManager
        .signinSilent()
        .then((user) => {
          isRefreshing = false;
          request.headers.Authorization = `Bearer ${user.access_token}`;
          processQueue(null, user.access_token);
        })
        .catch((error) => {
          isRefreshing = false;
          processQueue(error, null);
        });
    }

    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject });
    })
      .then((token) => {
        request.headers.Authorization = `Bearer ${token}`;
        return request;
      })
      .catch((error) => Promise.reject(error));
  }
};

const createApiClient = (version: number) => {
  const apiClient = axios.create();
  if (version === 1) {
    apiClient.defaults.baseURL = import.meta.env.VITE_APP_REST_API_URL;
  } else {
    apiClient.defaults.baseURL = _.replace(
      import.meta.env.VITE_APP_REST_API_URL,
      'v1',
      `v${version}`,
    );
  }
  apiClient.interceptors.response.use(
    responseInterceptorFulfilled,
    responseInterceptorRejected,
  );
  apiClient.interceptors.request.use(interceptorRequestHandler, (error) => {
    return Promise.reject(error);
  });
  return apiClient;
};

const apiClient = createApiClient(1);
const apiClientV2 = createApiClient(2);
const apiClientV3 = createApiClient(3);

export { apiClient, apiClientV2, apiClientV3 };
