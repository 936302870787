import { addCompanyAccount } from '@/app/slices/userSlice';
import userManager from '@/app/userManager';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BorderHeading from 'ui/components/BorderHeading';
import { PrimaryButton } from 'ui/components/Button';
import FieldHeader from 'ui/components/FieldHeading';
import Link from 'ui/components/Link';
import TextField from 'ui/components/TextField';
import LandingLayout from 'ui/layouts/LandingLayout';
import { Alert, Box, Typography } from 'ui/mui';

const spacing = 2;

const AddCompanyUser = () => {
  const navigate = useNavigate();
  const [formError, setFormError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState<
    { message: string } | null | false
  >(null);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm();

  return (
    <>
      <LandingLayout>
        <Box sx={{ mb: spacing }}>
          <BorderHeading
            title={'Lägg till nytt företag'}
            subtitle='Ditt personnummer är inte kopplat till något företag. För att logga in till företagets konto behöver du lägga till dig som användare. Är ni flera som ska administrera företagets konto kan du lägga till användare när du är inloggad.'
          ></BorderHeading>
        </Box>
        <form
          onSubmit={handleSubmit(
            ({
              companyIdentificationNumber,
              companyCustomerNumber,
              userName,
              userEmail,
            }) => {
              setLoading(true);
              addCompanyAccount({
                companyIdentificationNumber,
                companyCustomerNumber,
                userName,
                userEmail,
              })()
                .then((response) => {
                  setLoading(false);
                  if (response?.success) {
                    setFormError(null);
                    setFormSuccess({
                      message: 'Du är nu kopplad mot företaget.',
                    });
                    userManager.signinRedirect();
                  } else {
                    setFormError(response?.message);
                    setFormSuccess(false);
                  }
                })
                .catch(() => {
                  setLoading(false);
                  setFormError('Något gick fel. Försök igen senare.');
                  setFormSuccess(false);
                });
            },
          )}
        >
          <Box sx={{ mb: spacing }}>
            <FieldHeader>Företagsuppgifter</FieldHeader>
            <Controller
              control={control}
              name='companyCustomerNumber'
              defaultValue=''
              rules={{
                required: 'Det här fältet krävs',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Enbart siffror är tillåtna',
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    label='Kundnummer'
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='companyIdentificationNumber'
              defaultValue=''
              rules={{
                required: 'Det här fältet krävs',
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    label='Organisationsnummer'
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{ mb: spacing }}>
            <FieldHeader>Dina uppgifter</FieldHeader>
            <Controller
              control={control}
              name='userName'
              defaultValue=''
              rules={{
                required: 'Det här fältet krävs',
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    label='Namn'
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='userEmail'
              defaultValue=''
              rules={{
                required: 'Det här fältet krävs',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Ange en giltig mejladress',
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    label='Mejl'
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                );
              }}
            />
          </Box>
          {formError && (
            <Alert severity='error' variant='outlined' sx={{ mb: spacing }}>
              {formError}
            </Alert>
          )}
          {formSuccess && (
            <Alert severity='success' variant='outlined' sx={{ mb: spacing }}>
              {formSuccess.message}
            </Alert>
          )}
          <Box sx={{ mb: spacing }}>
            <PrimaryButton
              type='submit'
              fullWidth
              disabled={loading}
              size='large'
            >
              {loading ? 'Lägger till' : 'Lägg till'}
            </PrimaryButton>
          </Box>
        </form>
        <Box sx={{ textAlign: 'center', pt: 2 }}>
          <Typography>
            Inte företag?{' '}
            <Link
              href='/login'
              onClick={(event) => {
                event.preventDefault();
                navigate('/signout');
              }}
            >
              Logga in som privatperson
            </Link>
          </Typography>
        </Box>
      </LandingLayout>
    </>
  );
};

export default AddCompanyUser;
