import { Account, Customer } from '@/types/Customer';
import { LinkedUser, Property, State } from '@/types/State';
import { Dispatch, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { compact, each, filter, find, first, get, includes, map } from 'lodash';
import { NON_CUSTOMER_CUSTOMER_ID } from '../../helpers/constants';
import { customerIsMixed, userIsIndividual } from '../../helpers/utils';
import { apiClientV2 } from '../axios.config';

interface UserState {
  account: Account | null;
  customer: Customer | null;
  selectedCustomer: Customer | null;
  selectedCustomerAccountId: number | null;
  selectedProperty: Property | null;
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    account: null,
    customer: null,
    selectedCustomer: null,
    selectedCustomerAccountId: null,
    selectedProperty: null,
  } as UserState,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
    },
    setUserDetails: (state, action) => {
      if (state.customer) {
        state.customer.customerAccounts[0] = {
          ...state.customer.customerAccounts[0],
          ...action.payload,
          mobilePhone: action.payload.phoneNumber,
        };
      }
      if (state.selectedCustomer) {
        state.selectedCustomer.customerAccounts[0] = {
          ...state.selectedCustomer.customerAccounts[0],
          ...action.payload,
          mobilePhone: action.payload.phoneNumber,
        };
      }
    },
    setSelectedCustomerFacilities: (state, action) => {
      if (state.selectedCustomer) {
        state.selectedCustomer.facilities = map(
          state.selectedCustomer.facilities,
          (facility) => {
            const payloadFacility = find(action.payload, {
              facilityKey: facility.facilityKey,
            });
            return { ...facility, ...payloadFacility };
          },
        );
      }
    },
    setSelectedCustomerGroups: (state, action) => {
      if (state.selectedCustomer) {
        state.selectedCustomer.groups = compact([
          state.selectedCustomer.groups[0],
          ...action.payload,
        ]);
      }
    },
    addLinkedUser: (state, action) => {
      const { user, customerId } = action.payload;
      if (!state.selectedCustomer || !state.customer) {
        return;
      }
      state.selectedCustomer.linkedUsers = state.selectedCustomer.linkedUsers
        ? [...state.selectedCustomer.linkedUsers, user]
        : [user];

      if (customerId === get(state, 'customer.customerId')) {
        state.customer.linkedUsers = state.customer.linkedUsers
          ? [...state.customer.linkedUsers, user]
          : [user];
      }
    },
    removeLinkedUser: (state, action) => {
      const { user, customerId } = action.payload;
      if (state.selectedCustomer) {
        state.selectedCustomer.linkedUsers = filter(
          state.selectedCustomer.linkedUsers,
          (u) => u.userId !== user.userId,
        );
      }
      if (customerId === get(state, 'customer.customerId') && state.customer) {
        state.customer.linkedUsers = filter(
          state.customer.linkedUsers,
          (u) => u.userId !== user.userId,
        );
      }
    },
    setSelectedCustomerAccountId: (state, action) => {
      state.selectedCustomerAccountId = action.payload;
    },
  },
});

export const {
  setAccount,
  setCustomer,
  setSelectedCustomer,
  setSelectedProperty,
  setUserDetails,
  addLinkedUser,
  removeLinkedUser,
  setSelectedCustomerAccountId,
  setSelectedCustomerFacilities,
  setSelectedCustomerGroups,
} = userSlice.actions;

export const fetchAccount = () => (dispatch: Dispatch) =>
  apiClientV2
    .get('account')
    .then((response) => response?.data?.data?.[0])
    .then((account) => dispatch(setAccount(account)))
    .catch(console.error);

export const fetchAccountCustomerId =
  (customerId: number) => (dispatch: Dispatch) =>
    apiClientV2(`account/customer/${customerId}`)
      .then((response) => response?.data?.data?.[0])
      .then((account) => {
        if (account?.exist) {
          dispatch(setCustomer(account));
        }
      })
      .catch(console.error);

export const fetchCustomer =
  (
    customerId: number,
    isLinked: boolean | null = null,
    customerAccountId?: number,
  ) =>
  (dispatch: Dispatch, getState: () => State) =>
    axios
      .all([
        apiClientV2.get(`customers/${customerId}`),
        apiClientV2.get(`account/customer/${customerId}`),
      ])
      .then(
        axios.spread((customerResponse, accountResponse) => {
          if (!customerResponse) {
            console.error('Kunde inte hämta kundinformation');
            return;
          }

          const customer = customerResponse?.data?.data?.[0];
          const account = accountResponse?.data?.data?.[0];
          const accountId =
            customerAccountId ||
            customer?.customerAccounts?.[0]?.customerAccountId;
          !isLinked && dispatch(setCustomer({ ...account, ...customer }));
          dispatch(setSelectedCustomer({ ...account, ...customer, isLinked }));
          dispatch(setSelectedCustomerAccountId(accountId));
        }),
      )
      .then(async () => {
        const selectedCustomer = getSelectedCustomer(getState());
        let initFacility: Property | undefined;
        if (selectedCustomer) {
          initFacility =
            (first(selectedCustomer?.groups) as Property) ||
            first(selectedCustomer?.facilities);
        }

        if (initFacility === undefined) {
          initFacility = {} as Property;
        }

        dispatch(setSelectedProperty(initFacility));

        try {
          const isIndividual = userIsIndividual(selectedCustomer);
          // @ts-ignore
          window.isOrganization = !isIndividual;
        } catch (error) {
          console.error(error);
        }

        return selectedCustomer;
      })
      .catch(console.error);

export const setNonCustomer =
  (userIsInactive: boolean) => (dispatch: Dispatch, getState: () => State) => {
    return new Promise((resolve) => {
      Promise.all([
        dispatch(
          setSelectedProperty({
            infraServices: [],
          }),
        ),
        dispatch(
          setSelectedCustomer({
            ...(userIsInactive ? getState().user.selectedCustomer : []),
            customerId: NON_CUSTOMER_CUSTOMER_ID,
            status: { id: 0 },
          }),
        ),
        !userIsInactive && dispatch(setSelectedCustomerAccountId(null)),
      ])
        .then(resolve)
        .catch(console.error);
    });
  };

export const updateUser =
  (values: { displayName: string; email: string; phoneNumber: string }) =>
  (dispatch: Dispatch) => {
    const updateFunc = apiClientV2.put('account', values);
    return updateFunc.then(() => {
      dispatch(setUserDetails(values));
    });
  };

export const addLinkedUsers =
  (users: LinkedUser[], customerId: number) => (dispatch: Dispatch) => {
    return new Promise((resolve) => {
      each(users, (user) => {
        apiClientV2
          .post(`account/customer/${customerId}/users`, { users })
          .then(() => dispatch(addLinkedUser({ user, customerId })))
          .catch(console.error);
      });
      resolve('done');
    });
  };

export const deleteLinkedUser =
  (user: LinkedUser, customerIdToDelete?: number) =>
  (dispatch: Dispatch, getState: () => State) => {
    const customerId =
      customerIdToDelete || getSelectedCustomer(getState())?.customerId;

    return apiClientV2
      .delete(`account/customer/${customerId}/users/${user.userId}`)
      .then(() => {
        dispatch(removeLinkedUser({ user, customerId }));
      })
      .catch(console.error);
  };

export const deleteLinkedUsers =
  (users: number[], customerIdToDelete: number) =>
  (dispatch: Dispatch, getState: () => State) => {
    const customerId =
      customerIdToDelete || getSelectedCustomer(getState())?.customerId;

    return apiClientV2
      .delete(`account/customer/${customerId}/users`, {
        data: [...users],
      })
      .then(() => {
        each(users, (user) => dispatch(removeLinkedUser({ user, customerId })));
      })
      .catch(console.error);
  };

const selectedCustomer = (state: State) => state.user.selectedCustomer;
const selectedCustomerAccountId = (state: State) =>
  state.user.selectedCustomerAccountId;
const account = (state: State) => state.user.account;

export const getSelectedCustomer = createSelector(
  [selectedCustomer, selectedCustomerAccountId, account],
  (customer, accountId, account) => {
    return customer
      ? {
          ...customer,
          ...(find(
            customer.customerAccounts,
            (a) => a.customerAccountId === accountId,
          )
            ? find(
                customer.customerAccounts,
                (a) => a.customerAccountId === accountId,
              )
            : account),
          facilities: customerIsMixed(customer)
            ? filter(customer.facilities, (f) =>
                includes(f.customerAccountIds, accountId),
              )
            : customer.facilities,
          groups: customer.groups,
        }
      : null;
  },
);

export const addCompanyAccount =
  (values: {
    companyIdentificationNumber: string;
    companyCustomerNumber: string;
    userName: string;
    userEmail: string;
  }) =>
  () => {
    return apiClientV2
      .post('account/company', values)
      .then((response) => response?.data?.data?.[0])
      .catch((error) => {
        return (
          get(error, 'response.data.data[0]') || error?.response?.data?.error
        );
      });
  };

export const fetchSelectedCustomerFacilities =
  () => (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());
    if (selectedCustomer?.customerId) {
      return apiClientV2
        .get(
          `customers/${selectedCustomer?.customerId}/facilities?pageSize=10000`,
        )
        .then((response) => response?.data?.data?.[0]?.items)
        .then((facilities) => {
          const newSelectedProperty = find(
            facilities,
            (f) =>
              f.facilityKey === getState()?.user?.selectedProperty?.facilityKey,
          );
          if (newSelectedProperty) {
            dispatch(
              setSelectedProperty({
                ...getState()?.user?.selectedProperty,
                ...newSelectedProperty,
              }),
            );
          }
          return dispatch(setSelectedCustomerFacilities(facilities));
        })
        .catch(console.error);
    }
  };

export const fetchSelectedCustomerGroups =
  () => (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());
    return apiClientV2
      .get(`customers/${selectedCustomer?.customerId}/groups?pageSize=10000`)
      .then((response) => response?.data?.data?.[0]?.items)
      .then((groups) => {
        const newSelectedProperty = find(
          groups,
          (f) => f.groupId === getState()?.user?.selectedProperty?.groupId,
        );
        if (newSelectedProperty) {
          dispatch(
            setSelectedProperty({
              ...getState()?.user?.selectedProperty,
              ...newSelectedProperty,
            }),
          );
        }
        return dispatch(setSelectedCustomerGroups(groups));
      })
      .catch(console.error);
  };

export default userSlice.reducer;
