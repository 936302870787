import PageFooter from 'ui/components/PageFooter';
import { Box } from 'ui/mui';

type CleanLayoutProps = {
  children: React.ReactNode;
};

const CleanLayout = ({ children }: CleanLayoutProps) => (
  <Box
    sx={{
      background: (theme) => theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Box
      sx={{
        pt: 8,
        flex: '1 0 auto',
      }}
    >
      <Box
        sx={{
          maxWidth: 'md',
          mx: 'auto',
          mt: 0,
          px: 1,
        }}
      >
        {children}
      </Box>
    </Box>
    <Box
      sx={{
        flexShrink: 0,
      }}
    >
      <PageFooter />
    </Box>
  </Box>
);

export default CleanLayout;
