import SidebarMenu from '@/layout/sidebar/components/SidebarMenu';
import { Customer } from '@/types/Customer';
import { State } from '@/types/State';
import { compact, get, includes, map } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from 'ui';
import {
  IconAccountCircle,
  IconCompareArrows,
  IconContactMail,
  IconEmail,
  IconLogout,
  IconNotificationAdd,
  IconPerson,
} from 'ui/Icons';
import { Box, Button, Menu, Typography } from 'ui/mui';
import { hexToRgba } from 'ui/utils/color';
import { getSelectedCustomer } from '../../app/slices/userSlice';
import {
  customerIsMixed,
  loggedInAsCompany,
  userIsActive,
  userIsCustomer,
  userIsUpcoming,
} from '../../helpers/utils';

type ProfileMenuProps = {
  mobileOpen?: boolean;
  setMobileOpen?: (open: boolean) => void;
};
type ProfileHeadProps = {
  mobileOpen?: boolean;
};

const ProfileHead = ({ mobileOpen = false }: ProfileHeadProps) => {
  const selectedCustomer = useSelector((state: State) =>
    getSelectedCustomer(state),
  ) as Customer;
  return (
    <>
      <IconAccountCircle color='secondary' sx={{ fontSize: '3rem', pb: 1 }} />
      <Typography
        variant={mobileOpen ? 'h1' : 'body1'}
        color='secondary'
        component='span'
        sx={{
          overflow: 'hidden',
          whiteSpace: 'normal',
          textOverflow: 'ellipsis',
          px: 2,
          textTransform: 'capitalize',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {(
          selectedCustomer?.displayName || selectedCustomer?.name
        )?.toLocaleLowerCase()}
      </Typography>
    </>
  );
};

const ProfileMenu = ({ mobileOpen, setMobileOpen }: ProfileMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const desktopOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const roles = useSelector((state: State) => state?.auth?.user?.profile.role);
  const customer = useSelector((state: State) => state.user.customer);
  const linkedCustomers = useSelector(
    (state: State) => state?.user?.account?.linkedCustomers,
  );
  const account = useSelector((state: State) => state.user.account);
  const authProfile = useSelector(
    (state: State) => state.auth.user && state.auth.user.profile,
  );
  const selectedCustomer = useSelector((state: State) =>
    getSelectedCustomer(state),
  ) as Customer;

  const [availableCustomers, setAvailableCustomers] = useState<boolean>(false);

  const hideHandleUsers = useMemo(() => {
    return (
      !userIsCustomer(selectedCustomer) && !userIsUpcoming(selectedCustomer)
    );
  }, [selectedCustomer]);

  const hideAccountLink = useMemo(() => {
    return (
      !selectedCustomer.isLinked &&
      !get(account, 'exist') &&
      (!includes(roles, 'KC') || !includes(roles, 'Admin'))
    );
  }, [selectedCustomer.isLinked, account, roles]);

  const handleLogout = useCallback(() => {
    navigate('/signout');
  }, [navigate]);

  useEffect(() => {
    const arr = customer
      ? customerIsMixed(customer)
        ? compact(
          map(get(customer, 'customerAccounts'), (customerAccount) => {
            return (
              userIsActive(customerAccount) && {
                ...customer,
                ...customerAccount,
              }
            );
          }),
        )
        : [{ ...customer, ...customer?.customerAccounts?.[0], id: account?.id }]
      : [{ ...account }];

    setAvailableCustomers(arr?.length > 1 || linkedCustomers?.length > 0);
  }, [selectedCustomer, account, customer, linkedCustomers]);

  const menuItems = useMemo(() => {
    const items: { title: string; path: string; icon: ReactNode }[] = [];

    if (!hideAccountLink) {
      items.push({
        title: 'Kontaktuppgifter',
        path: '/kontaktuppgifter',
        icon: <IconContactMail />,
      });
    }
    if (!hideHandleUsers) {
      items.push({
        title: 'Användare',
        path: '/users',
        icon: <IconPerson />,
      });
      items.push({
        title: 'Avisering vid avbrott',
        path: '/avisering-vid-avbrott',
        icon: <IconNotificationAdd />,
      });
    }
    if (loggedInAsCompany(authProfile)) {
      items.push({
        title: 'Lägg till nytt företag',
        path: '/foretag/skapa-anvandare',
        icon: <IconAccountCircle />,
      });
    }
    items.push({
      title: 'Ärenden',
      path: '/arenden',
      icon: <IconEmail />,
    });

    return items;
  }, [authProfile, hideAccountLink, hideHandleUsers]);

  const someRouteIsActive = useMemo(() => {
    return menuItems.some((item) => location.pathname === item.path);
  }, [location.pathname, menuItems]);

  if (!authProfile) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: { xs: 1, md: 'unset' },
        }}
      >
        {mobileOpen ? (
          <Box
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              py: 3,
              display: 'flex',
            }}
          >
            <ProfileHead mobileOpen={true} />
          </Box>
        ) : (
          <Button
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              py: 3,
              display: 'flex',
              borderRadius: 0,
              borderLeft: '4px solid transparent',
              borderLeftColor: (theme) =>
                desktopOpen || someRouteIsActive
                  ? theme.palette.accent.main
                  : 'transparent',
              backgroundColor: (theme) =>
                desktopOpen || someRouteIsActive
                  ? hexToRgba(theme.palette.common.white, 0.16)
                  : 'unset',
            }}
            onClick={handleClick}
            aria-controls={desktopOpen ? 'profile-menu' : undefined}
            aria-haspopup='menu'
            title={(
              selectedCustomer?.displayName || selectedCustomer?.name
            )?.toLocaleLowerCase()}
          >
            <ProfileHead />
          </Button>
        )}
        {mobileOpen ? (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
            }}
          >
            <SidebarMenu
              items={menuItems}
              handleClose={() => {
                handleClose();
                setMobileOpen && setMobileOpen(false);
              }}
            />
            <Box sx={{ p: 2, pb: 4 }}>
              {availableCustomers && (
                <SecondaryButton
                  onClick={() => navigate('/switch-user')}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <IconCompareArrows fontSize='small' sx={{ mr: 1 }} />
                  Växla konto
                </SecondaryButton>
              )}
              <PrimaryButton onClick={handleLogout} fullWidth>
                <IconLogout fontSize='small' sx={{ mr: 1 }} />
                Logga ut
              </PrimaryButton>
            </Box>
          </Box>
        ) : (
          <Menu
            open={desktopOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ minWidth: '500px' }}>
              <SidebarMenu
                items={menuItems}
                handleClose={() => {
                  handleClose();
                  setMobileOpen && setMobileOpen(false);
                }}
              />
              <Box sx={{ p: 2, pb: 4 }}>
                {availableCustomers && (
                  <SecondaryButton
                    onClick={() => navigate('/switch-user')}
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <IconCompareArrows fontSize='small' sx={{ mr: 1 }} />
                    Växla konto
                  </SecondaryButton>
                )}
                <PrimaryButton onClick={handleLogout} fullWidth>
                  <IconLogout fontSize='small' sx={{ mr: 1 }} />
                  Logga ut
                </PrimaryButton>
              </Box>
            </Box>
          </Menu>
        )}
      </Box>
    </>
  );
};

export default ProfileMenu;
