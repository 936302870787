import { createSlice } from '@reduxjs/toolkit';
import { apiClientV2 } from '../../app/axios.config';
import { getSelectedCustomer } from '../../app/slices/userSlice';
import { findIndex } from 'lodash';

export const contractSlice = createSlice({
  name: 'contracts',
  initialState: {
    contractList: null,
    groupedContracts: null,
  },
  reducers: {
    setContracts: (state, action) => {
      state.contractList = action.payload;
    },
    setGroupedContracts: (state, action) => {
      state.groupedContracts = action.payload;
    },
    setContractDetails: (state, action) => {
      const currentContract = action.payload.contractDetails.utilityType.name;
      const contractGroup = state.groupedContracts[currentContract];
      const contractIndex = findIndex(
        contractGroup,
        (item) => item.contractId === action.payload.contractId,
      );
      state.groupedContracts[currentContract][contractIndex] = {
        ...contractGroup[contractIndex],
        contractDetails: action.payload.contractDetails,
      };
    },
    setContractDetailsNotFound: (state, action) => {
      const currentContract = Object.keys(state.groupedContracts).find(
        (utilityName) => {
          return state.groupedContracts[utilityName].find(
            (contract) => contract.contractId === action.payload.contractId,
          );
        },
      );
      const contractGroup = state.groupedContracts[currentContract];
      const contractIndex = findIndex(
        contractGroup,
        (item) => item.contractId === action.payload.contractId,
      );
      state.groupedContracts[currentContract][contractIndex] = {
        ...contractGroup[contractIndex],
        contractDetails: null,
      };
    },
  },
});

export const {
  setContracts,
  setContractDetails,
  setGroupedContracts,
  setContractDetailsNotFound,
} = contractSlice.actions;

export const fetchContracQuery = (
  selectedCustomer,
  facilityKey,
  groupId,
  infraService,
  paginateOptions,
) => {
  return apiClientV2
    .get(`customers/${selectedCustomer.customerId}/contracts`, {
      params: {
        facilityKey,
        groupId,
        infraService: infraService && infraService.toLowerCase(),
        ...paginateOptions,
      },
    })
    .then((response) => response?.data?.data?.[0]);
};

export const fetchContracts =
  (infraService, paginateOptions) => (dispatch, getState) => {
    const { selectedProperty } = getState().user;
    const { facilityKey, groupId } = selectedProperty;
    const selectedCustomer = getSelectedCustomer(getState());

    return fetchContracQuery(
      selectedCustomer,
      facilityKey,
      groupId,
      infraService,
      paginateOptions,
    ).then((contracts) => dispatch(setContracts(contracts)));
  };

export const fetchGroupedContracts = (infraService) => (dispatch, getState) => {
  const { selectedProperty } = getState().user;
  const { facilityKey, groupId } = selectedProperty;
  const selectedCustomer = getSelectedCustomer(getState());

  return apiClientV2
    .get(`customers/${selectedCustomer.customerId}/contracts/grouped`, {
      params: {
        facilityKey,
        groupId,
        infraService: infraService && infraService.toLowerCase(),
      },
    })
    .then((response) => response?.data?.data?.[0])
    .then((contracts) => dispatch(setGroupedContracts(contracts)));
};

export const fetchContractDetails = (contractId) => (dispatch, getState) => {
  const selectedCustomer = getSelectedCustomer(getState());
  return apiClientV2
    .get(
      `customers/${selectedCustomer.customerId}/contracts/${contractId}/details`,
    )
    .then((contractDetails) => {
      dispatch(
        setContractDetails({
          contractId,
          contractDetails: contractDetails.data,
        }),
      );
    })
    .catch((error) => {
      dispatch(
        setContractDetailsNotFound({
          contractId,
        }),
      );
    });
};

export default contractSlice.reducer;
