import { Box } from "@mui/material";
import { ReactNode } from "react";
import PageFooter from "../components/PageFooter";
import Logo from "../components/logo/Logo";

type LandingLayoutProps = {
  children: ReactNode;
};

const svgDataUri = function (color: string) {
  const encodedSvgDataUri = encodeURIComponent(
    `<svg viewBox="0 0 732 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M711.679 -8.88245e-07C663.106 183.067 649.218 346.346 649.218 464.967C649.218 652.311 673.828 839.304 732 1024L0.000138345 1024L0.000183105 -3.19967e-05L711.679 -8.88245e-07Z" fill="${color}"/>
      </svg>`
  );
  return `data:image/svg+xml;utf8,${encodedSvgDataUri}`;
};

const LandingLayout = ({ children }: LandingLayoutProps) => {
  const breakPointWidth = { md: "40%", lg: "50%" };

  return (
    <Box
      sx={{
        pl: breakPointWidth,
        "&:before": {
          content: '""',
          position: "fixed",
          display: { sx: "hidden", md: "block" },
          width: breakPointWidth,
          left: 0,
          height: "100vh",
          backgroundImage: (theme) =>
            `url(${svgDataUri(theme.palette.primary.dark)})`,
          backgroundRepeat: "no-repeat",
          backgroundScroll: "fixed",
          backgroundPosition: "right center",
          backgroundSize: "cover",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          minHeight: "100vh",
        }}
      >
        <Box sx={{ display: "block", textAlign: "right", mr: 2 }}>
          <Logo size={{ xs: 55, md: 86 }} />
        </Box>
        <Box
          sx={{
            px: 2,
            py: 4,
            minHeight: "100%",
            maxWidth: { xs: "100%", md: "70ch" },
            flex: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>{children}</Box>
        </Box>
        <Box
          sx={{
            display: { md: "none" },
          }}
        >
          <PageFooter hideLogo />
        </Box>
      </Box>
    </Box>
  );
};
export default LandingLayout;
