import { trackingInit } from '@/helpers/trackingHelper';
import 'dayjs/locale/sv';
import Highcharts from 'highcharts';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { processSilentRenew } from 'redux-oidc';
import { LocalizationProvider, useMediaQuery } from 'ui/mui';
import ThemeProvider from 'ui/theme/ThemeProvider';
import { getSelectedCustomer } from './app/slices/userSlice';
import userManager from './app/userManager';
import { addToWindowObject } from './helpers/utils';
import Layout from './layout/Layout';
import AddCompanyUser from './pages/_company-pages/add-company-user/AddCompanyUser';
import Callback from './pages/callback/Callback';
import NotFound from './pages/not-found/NotFound';
import Signout from './pages/signout/Signout';
import ErrorWrapper from './ErrorWrapper';
import SwitchUser from './pages/switch-user/SwitchUser';
import ScrollToTop from '@/features/routes/ScrollToTop';
import { AdapterDayjs } from 'ui/mui';
import { State } from './types/State';

addToWindowObject('Highcharts', Highcharts);

const App = () => {
  processSilentRenew();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);
  const { selectedProperty } = useSelector((state: State) => state.user);
  const { user, isLoadingUser } = useSelector((state: State) => state.auth);
  const selectedCustomer = useSelector((state: State) =>
    getSelectedCustomer(state),
  );

  const signinFunc = debounce(
    () => {
      localStorage.setItem(
        'redirectUri',
        window.location.pathname + window.location.search,
      );
      userManager.signinRedirect();
    },
    500,
    { leading: true, trailing: true },
  );

  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  useEffect(() => {
    if (
      (!user || !selectedCustomer) &&
      window.location.pathname !== '/callback' &&
      window.location.pathname !== '/signout' &&
      window.location.pathname !== '/silent-renew' &&
      window.location.pathname !== '/foretag/skapa-anvandare'
    ) {
      signinFunc();
    }
  }, [isLoadingUser, user, selectedCustomer, signinFunc]);

  const appReady = !!selectedCustomer && !!selectedProperty && !!user;

  useEffect(() => {
    trackingInit();
  }, []);

  return (
    <ThemeProvider darkMode={darkMode}>
      <ErrorWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
          <ScrollToTop />
          <Routes>
            <Route path='/callback' element={<Callback />} />
            <Route
              path='/switch-user/:customerAccountId?'
              element={<SwitchUser />}
            />
            <Route path='/signout' element={<Signout />} />
            <Route
              path='/foretag/skapa-anvandare'
              element={<AddCompanyUser />}
            />
            {appReady && <Route path='/*' element={<Layout />} />}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </LocalizationProvider>
      </ErrorWrapper>
    </ThemeProvider>
  );
};

export default App;
