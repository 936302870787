import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps,
  styled,
} from "@mui/material";
import { buttonRadius } from "../../theme/theme";
import { hexToRgba } from "../../utils/color";

const StyledToggleButton = styled(MuiToggleButton)`
  color: ${({ theme }) => theme.palette.text.secondary};
  border-color: ${({ theme }) => theme.palette.primary.main};
  border: 0;
  text-transform: none;
  border-radius: ${buttonRadius};
  text-transform: none;
  box-shadow: inset -2px 0px 0px transparent;
  transition: all 0.2s ease-in-out;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.palette.action.disabled};
  }
  &:hover {
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.primary.main, 0.12)};
    color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: inset -2px 0px 0px ${({ theme }) => theme.palette.primary.main};
  }
  :disabled {
    border: 0;
  }
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

StyledToggleButton.defaultProps = {
  color: "primary",
};

const ToggleButton = (props: ToggleButtonProps) => {
  return <StyledToggleButton {...props}>{props.children}</StyledToggleButton>;
};

export default ToggleButton;
