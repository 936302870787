import { PrimaryButton } from 'ui';
import StorageDetection from '../storage-detection/StorageDetection';
import CleanLayout from '@/layout/CleanLayout';
import BorderHeading from 'ui/components/BorderHeading';
import { Box, Typography } from 'ui/mui';

const NoUserError = () => {
  return (
    <CleanLayout>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <BorderHeading title='Beklagar inloggnings&shy;problemen' />
        <Box sx={{ textAlign: { sx: 'center', md: 'left' } }}>
          <Typography
            variant='h6'
            component='p'
            color='text.secondary'
            sx={{ mt: 1, maxWidth: '70ch' }}
          >
            Vi kan inte verifiera ditt konto just nu.
          </Typography>
          <StorageDetection />
          <Typography
            variant='h6'
            component='p'
            color='text.secondary'
            sx={{ mt: 1, maxWidth: '70ch' }}
          >
            Vänligen <b>logga ut</b> och försök sedan <b>logga in</b> igen.
          </Typography>
          <PrimaryButton
            sx={{ zIndex: 10, mt: 2 }}
            onClick={() => window.location.assign('/signout')}
          >
            Logga ut
          </PrimaryButton>
        </Box>
      </Box>
    </CleanLayout>
  );
};

export default NoUserError;
