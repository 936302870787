import { Box, useTheme } from "../../mui";
import { lightModeColors } from "../../theme/theme";

export const WaveSvgD =
  "M0 27.6055C257.437 93.5919 487.049 112.457 653.859 112.457C917.312 112.457 1180.27 79.0256 1440 0V548H0L0 27.6055Z";

const FooterWave = () => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: "0px", display: "flex", minWidth: "100%" }}>
      <svg
        style={{ minWidth: "100%" }}
        viewBox="0 0 1440 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 27.6055C257.437 93.5919 487.049 112.457 653.859 112.457C917.312 112.457 1180.27 79.0256 1440 0V548H0L0 27.6055Z"
          fill={lightModeColors.primary.dark}
        />
      </svg>
    </Box>
  );
};

export default FooterWave;
