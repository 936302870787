import { Customer } from '@/types/Customer';
import { map } from 'lodash';
import { listBoxRadius } from 'ui';
import { IconCheck, IconChevronRight } from 'ui/Icons';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from 'ui/mui';
import { lightPrimaryShades8p } from 'ui/utils/color';

type UserListProps = {
  users: Customer[];
  selectedCustomer: Customer | null;
  setSwitchingCustomer: (customer: Customer) => void;
};

const UserList = ({
  users,
  selectedCustomer,
  setSwitchingCustomer,
}: UserListProps) => {
  function stringAvatar(name: string) {
    const nameParts = name.split(' ').filter((part) => part.trim() !== '');
    const initials = nameParts.map((part) => part[0]);
    return initials.join('');
  }
  return (
    <List>
      {map(users, (customer, index) => {
        const isCurrent =
          customer.customerId === selectedCustomer?.customerId &&
          customer?.name === selectedCustomer?.name;
        return (
          <ListItemButton
            key={customer?.customerId + index}
            onClick={() => {
              setSwitchingCustomer(customer);
            }}
            selected={isCurrent}
            sx={{
              borderLeft: '4px solid transparent',
              borderLeftColor: (theme) =>
                isCurrent ? theme.palette.accent.main : 'transparent',
              background: (theme) => theme.palette.background.default,
              mb: '2px',
              borderRadius: listBoxRadius,
              '&.Mui-selected, &:hover, &:active, &.Mui-focusVisible': {
                borderLeftColor: (theme) => theme.palette.accent.main,
                background: (theme) => theme.palette.background.default,
              },
              '&:hover .MuiListItemIcon-root .MuiAvatar-root, &:focus .MuiListItemIcon-root .MuiAvatar-root':
                {
                  color: (theme) => theme.palette.background.default,
                  background: (theme) => theme.palette.primary.main,
                },
            }}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  color: isCurrent ? 'primary.contrast' : 'primary.main',
                  background: (theme) =>
                    isCurrent
                      ? theme.palette.primary.main
                      : lightPrimaryShades8p(theme),
                  fontSize: 16,
                }}
              >
                {isCurrent ? <IconCheck /> : stringAvatar(customer.name)}
              </Avatar>
            </ListItemIcon>
            <ListItemText sx={{ textTransform: 'capitalize' }}>
              {typeof customer?.name === 'string'
                ? customer?.name?.toLowerCase()
                : customer?.name}
            </ListItemText>
            <IconChevronRight
              sx={{
                color: (theme) => theme.palette.action.active,
              }}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default UserList;
