import { ElementType, ReactNode } from "react";
import { IconArrowBack } from "../../Icons";
import { Box, Typography } from "../../mui";

const ConditionalBackLink = ({
  children,
  backHref,
  component,
}: {
  children: ReactNode;
  backHref?: string;
  component?: ElementType;
}) => {
  if (!backHref) {
    return <>{children}</>;
  }

  return (
    <Box
      href={backHref}
      to={backHref}
      component={component ?? "a"}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        color: (theme) =>
          theme.palette.mode === "dark"
            ? theme.palette.primary.light
            : theme.palette.primary.dark,
        textDecoration: "none",
        "&:hover, &:focus": {
          textDecoration: "underline",
        },
      }}
    >
      <IconArrowBack />
      {children}
    </Box>
  );
};

type BorderHeadingProps = {
  overline?: string;
  title: string | ReactNode;
  subtitle?: ReactNode;
  small?: boolean;
  backHref?: string;
  linkComponent?: ElementType;
  component?: ElementType;
};

const BorderHeading = ({
  overline,
  title,
  subtitle,
  small,
  backHref,
  linkComponent = "a",
  component = "h1",
}: BorderHeadingProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        pb: subtitle ? 2 : 4,
      }}
    >
      <Box
        sx={{
          mr: 2,
          display: "inline-block",
          position: "relative",
          pl: 2.5,
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "8px",
            borderRadius: "4px",
            background: (theme) => theme.palette.accent.main,
          },
        }}
      >
        {overline && (
          <ConditionalBackLink backHref={backHref} component={linkComponent}>
            <Typography
              variant="overline"
              color="text.secondary"
              sx={{ display: "inline-block" }}
            >
              {overline}
            </Typography>
          </ConditionalBackLink>
        )}
        <Typography
          variant={small ? "h3" : "h1"}
          component={component}
          sx={{
            color: (theme) => theme.palette.secondary.main,
            display: "block",
          }}
        >
          {title}
        </Typography>
      </Box>
      {subtitle && (
        <Typography
          variant="h6"
          component="h2"
          color="text.secondary"
          sx={{ mt: 1, maxWidth: "70ch" }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default BorderHeading;
