import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../axios.config';
import _ from 'lodash';
import { Subscription } from '@/pages/disruption-information/DisruptionInformation';

type InteruptionState = {
  subscriptions: Subscription[] | null;
  deleteError: boolean;
  deleteLoading: boolean;
};

const interruptionSlice = createSlice({
  name: 'interruptions',
  initialState: {
    subscriptions: null,
    deleteError: false,
    deleteLoading: false,
  } as InteruptionState,
  reducers: {
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload.subscriptions;
    },
    setDeleteError: (state, action) => {
      state.deleteError = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
  },
});

export const { setSubscriptions, setDeleteError, setDeleteLoading } =
  interruptionSlice.actions;

export const fetchSubscriptions = () => (dispatch: Dispatch) =>
  apiClient
    .get('interruption/subscriptions')
    .then((response) => response?.data?.data)
    .then((subscriptions) => dispatch(setSubscriptions({ subscriptions })));

export const addSubscription = (values: any) => (dispatch: Dispatch) => {
  return apiClient.post('interruption/subscriptions', values).then(() => {
    dispatch(fetchSubscriptions() as any);
  });
};

export const deleteSubscriptions =
  (subscriptionItem: Subscription, type?: 'sms' | 'email') =>
  (dispatch: Dispatch) => {
    dispatch(setDeleteLoading(true));
    Promise.all(
      _.map(subscriptionItem.customerNumbers, (customerNumber) => {
        const subscriptionsIDs = _(subscriptionItem.subscriptions)
          .filter(
            (subscription) => !type || subscription.notificationType === type,
          )
          .map((subscription) => subscription.id)
          .compact()
          .value();

        return apiClient.delete(
          `interruption/subscriptions/${customerNumber}`,
          {
            data: subscriptionsIDs,
          },
        );
      }),
    )
      .then(() => {
        dispatch(fetchSubscriptions() as any);
      })
      .catch(() => {
        console.log('Failed fetching');
      });
  };

export default interruptionSlice.reducer;
