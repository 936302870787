import { State } from '@/types/State';
import { useSelector } from 'react-redux';
import PageLoader from 'ui/components/PageLoader';
import { Box, Typography } from 'ui/mui';

const NotFound = () => {
  const loggedIn = useSelector((state: State) => state.auth.user);
  const { routes } = useSelector((state: State) => state.routes);
  return (
    <>
      {loggedIn && routes ? (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            pt: 5,
          }}
        >
          <>
            <Typography variant='h1' color='primary'>
              Hoppsan!
            </Typography>
            <Typography variant='h5'>
              Vi kan inte hitta sidan du letar efter.
            </Typography>
          </>
        </Box>
      ) : (
        <PageLoader title='Laddar...' />
      )}
    </>
  );
};

export default NotFound;
