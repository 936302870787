import { ReactNode, isValidElement, useEffect, useMemo, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { IconKeyboardArrowDown, IconKeyboardArrowUp } from 'ui/Icons';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  useTheme,
} from 'ui/mui';

export type SidebarMenuItemProps = {
  path: string;
  title: string;
  icon: ReactNode;
  childRoutes?: any;
  badgeCount?: number;
  handleClose?: () => void;
};

const SidebarMenuItem = ({
  path,
  title,
  icon,
  childRoutes,
  handleClose,
}: SidebarMenuItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const hasChildRoutes = useMemo(
    () => childRoutes && childRoutes.length > 0,
    [childRoutes],
  );

  const theme = useTheme();

  const match = useMatch(path + '/*');

  useEffect(() => {
    setIsActive(!!match);
  }, [match]);

  useEffect(() => {
    setIsExpanded(isActive);
  }, [isActive]);

  const itemStyle: SxProps<Theme> | undefined = {
    '& .MuiListItemText-primary': {
      color: (theme) => theme.palette.primary.main,
    },
    '& .MuiListItemText-primary:hover': {
      color: (theme) => theme.palette.secondary.main,
    },
    '&.Mui-selected .MuiListItemText-primary': {
      color: (theme) => theme.palette.secondary.main,
    },
  };

  return (
    <>
      <Box sx={{ display: "flex", borderLeftColor: isActive ? theme.palette.accent.main : 'transparent', background: isActive ? '#99a9b929' : 'transparent', ":hover": { background: "#ffffff14" }, ...itemStyle }} >
        <ListItemButton
          component={Link}
          to={path}
          sx={{ background: "transparent", ":hover": { background: "transparent" }, pr: 0 }}
          onClick={(evt) => {
            evt.preventDefault();
            navigate(path);
            handleClose && handleClose();
          }}
        >
          {isValidElement(icon) ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText>{title}</ListItemText>

        </ListItemButton>
        {hasChildRoutes && (
          <IconButton
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
            sx={{ m: 1 }}
            aria-label={
              isExpanded
                ? `Stäng undermeny för ${title}`
                : `Öppna undermeny för ${title}`
            }
          >
            {isExpanded ? <IconKeyboardArrowUp /> : <IconKeyboardArrowDown />}
          </IconButton>
        )}
      </Box>
      {hasChildRoutes ? (
        <Collapse in={isExpanded} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {childRoutes.map((menuItem: any, index: number) => (
              <ListItemButton
                key={menuItem.title + index}
                sx={{ pl: 4, ...itemStyle }}
                component={Link}
                to={menuItem.path}
                onClick={(evt) => {
                  evt.preventDefault();
                  navigate(menuItem.path);
                  handleClose && handleClose();
                }}
                selected={menuItem.path === window.location.pathname}
              >
                <>
                  <ListItemText>
                    <>{menuItem.title}</>
                  </ListItemText>
                </>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

export default SidebarMenuItem;
