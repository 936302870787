import { trackingSwitchCustomer } from '@/helpers/trackingHelper';
import CleanLayout from '@/layout/CleanLayout';
import PageHeading from '@/layout/page/PageHeading';
import { Customer } from '@/types/Customer';
import { State } from '@/types/State';
import {
  compact,
  filter,
  find,
  flatMap,
  get,
  includes,
  map,
  size,
} from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SecondaryButton } from 'ui';
import { IconSearch } from 'ui/Icons';
import PageLoader from 'ui/components/PageLoader';
import { Box, InputAdornment, TextField, Typography } from 'ui/mui';
import {
  fetchCustomer,
  fetchSelectedCustomerFacilities,
  getSelectedCustomer,
  setNonCustomer,
} from '../../app/slices/userSlice';
import {
  customerIsMixed,
  userIsActive,
  userIsCustomer,
  userIsInactive,
  userIsIndividual,
  userIsUpcoming,
} from '../../helpers/utils';
import UserList from './components/UserList';

const SwitchUser = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [filterValue, setFilterValue] = useState('');

  const { customerAccountId } = useParams<any>();

  const navigate = useNavigate();
  const [autoLogin, setAutoLogin] = useState(false);
  const [query, setQuery] = useState<any>({});
  const account = useSelector((state: State) => state.user.account);
  const customer = useSelector((state: State) => state.user.customer);
  const linkedCustomers = useSelector((state: State) =>
    get(state, 'user.account.linkedCustomers'),
  );
  const selectedCustomer = useSelector((state: State) =>
    getSelectedCustomer(state),
  );

  useEffect(() => {
    const tempQuery = new URLSearchParams(location.search);
    setQuery(tempQuery);
    setAutoLogin(Boolean(tempQuery.get('autoLogin')));
  }, [location.search]);

  const shouldRedirectFornya = !!localStorage.getItem('fornya');

  const arr = useMemo(() => {
    const tempArr: any[] = customer
      ? customerIsMixed(customer)
        ? compact(
            map(get(customer, 'customerAccounts'), (account) => {
              return (
                userIsActive(account) && {
                  ...customer,
                  ...account,
                  facilities: customerIsMixed(customer)
                    ? filter(customer.facilities, (f) =>
                        includes(
                          f?.customerAccountIds,
                          account?.customerAccountId,
                        ),
                      )
                    : customer.facilities,
                  groups: customer.groups,
                }
              );
            }),
          )
        : [{ ...customer, ...customer?.customerAccounts?.[0], id: account.id }]
      : [{ ...account }];

    if (linkedCustomers) {
      const linked = flatMap(linkedCustomers, (linkedCustomer) =>
        map(linkedCustomer?.customerAccounts, (customerAccount) => ({
          ...linkedCustomer,
          ...customerAccount,
          isLinked: linkedCustomer?.customerId,
        })),
      );
      linked.forEach((linkedCustomer) => {
        tempArr.push(linkedCustomer);
      });
    }

    return tempArr;
  }, [account, customer, linkedCustomers]);

  const filteredAccounts = useMemo(() => {
    return filter(arr, (item) => {
      return includes(item.name?.toLowerCase(), filterValue?.toLowerCase());
    });
  }, [arr, filterValue]);

  const filteredOrganizationAccounts = useMemo(() => {
    return filteredAccounts.filter(
      (account) => account?.customerType.name === 'ORGANIZATION',
    );
  }, [filteredAccounts]);

  const filteredPrivatAccounts = useMemo(() => {
    return filteredAccounts.filter(
      (account) => account?.customerType.name === 'INDIVIDUAL',
    );
  }, [filteredAccounts]);

  const filteredMixedAccounts = useMemo(() => {
    return filteredAccounts.filter(
      (account) => account?.customerType.name === 'MIXED',
    );
  }, [filteredAccounts]);

  const rememberedId: any = localStorage.getItem(
    `me_${import.meta.env.VITE_APP_ME_ENV}_remember_user_${account?.id}`,
  );
  const rememberUser = !!rememberedId;
  const [switchingCustomer, setSwitchingCustomer] = useState(
    find(arr, {
      customerAccountId: autoLogin
        ? parseInt(rememberedId)
        : parseInt(customerAccountId as string),
    }) || arr[customerAccountId as unknown as number],
  );

  const handleChange = useCallback((event: any) => {
    const value = event ? event.target.value : '';
    setFilterValue(value);
  }, []);

  const handleRememberUser = useCallback(() => {
    if (rememberUser) {
      localStorage.setItem(
        `me_${import.meta.env.VITE_APP_ME_ENV}_remember_user_${account?.id}`,
        switchingCustomer?.customerAccountId,
      );
    } else {
      localStorage.removeItem(
        `me_${import.meta.env.VITE_APP_ME_ENV}_remember_user_${account?.id}`,
      );
    }
  }, [account?.id, rememberUser, switchingCustomer?.customerAccountId]);

  const fetchNewCustomer = useCallback(() => {
    handleRememberUser();
    if (
      userIsCustomer(switchingCustomer) ||
      userIsInactive(switchingCustomer)
    ) {
      dispatch(
        fetchCustomer(
          switchingCustomer.customerId,
          switchingCustomer.isLinked,
          switchingCustomer.customerAccountId,
        ) as any,
      ).then((customer: Customer) => {
        trackingSwitchCustomer(customer);
      });
    } else {
      dispatch(setNonCustomer(userIsInactive(selectedCustomer)) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRememberUser, selectedCustomer, switchingCustomer]);

  const redirectToFornya = useCallback((customer: Customer) => {
    const isCompany = !userIsIndividual(customer);
    const customerNumber = customer.customerNumber;
    const tempWin: any = window;
    const token = tempWin.token;
    const redirectUrl = `${
      import.meta.env.VITE_APP_OLD_ME_URL
    }?customerNumber=${customerNumber}&token=${token}&returnurl=/${
      isCompany ? 'foretag/' : ''
    }fornya-elavtal`;
    localStorage.removeItem('fornya');
    window.location.href = redirectUrl;
  }, []);

  useEffect(() => {
    if (
      switchingCustomer &&
      selectedCustomer &&
      switchingCustomer.customerAccountId === selectedCustomer.customerAccountId
    ) {
      shouldRedirectFornya && redirectToFornya(customer);

      setTimeout(() => {
        userIsCustomer(selectedCustomer) || userIsUpcoming(selectedCustomer)
          ? navigate(query?.get('redirect') || '/')
          : dispatch(setNonCustomer(userIsInactive(selectedCustomer)) as any)
              .then(() => navigate('/non-customer'))
              .catch(() => navigate('/non-customer'));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    switchingCustomer,
    selectedCustomer,
    shouldRedirectFornya,
    customer,
    navigate,
    query,
    redirectToFornya,
  ]);

  useEffect(() => {
    switchingCustomer && fetchNewCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchingCustomer]);

  useEffect(() => {
    if (
      selectedCustomer?.identificationNumber ===
      switchingCustomer?.identificationNumber
    ) {
      try {
        userIsCustomer(selectedCustomer)
          ? dispatch(fetchSelectedCustomerFacilities() as any)
          : null;
      } catch (error) {
        console.error('Tracking change', error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, switchingCustomer]);

  useEffect(() => {
    if (!switchingCustomer && arr && arr.length <= 1) {
      navigate('/');
    }
  }, [arr, navigate, switchingCustomer]);

  return (
    <>
      {switchingCustomer && (
        <PageLoader
          title={`Byter konto till ${
            switchingCustomer.displayName || switchingCustomer.name
          }`}
        />
      )}
      {!switchingCustomer && (
        <CleanLayout>
          <Box>
            {arr && arr.length > 1 && (
              <>
                <PageHeading
                  route={{
                    title: 'Konton',
                    pageHeaderOptions: {
                      hideProperty: true,
                      hide: false,
                      propertyText: '',
                      title: 'Konton',
                      subtitle:
                        'Du har tillgång till flera konton. Välj konto för att gå vidare.',
                    },
                  }}
                />
                {size([
                  ...filteredPrivatAccounts,
                  ...filteredOrganizationAccounts,
                  ...filteredMixedAccounts,
                ]) > 10 && (
                  <Box
                    sx={{ mt: 2, mb: 4, maxWidth: { xs: '100%', md: '50%' } }}
                  >
                    <TextField
                      size='small'
                      type='search'
                      color='primary'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconSearch
                              sx={{
                                color: (theme) => theme.palette.text.primary,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={filterValue}
                      placeholder='Sök konto'
                      onChange={handleChange}
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input::placeholder': {
                          color: (theme) => theme.palette.text.primary,
                          opacity: 0.8,
                        },
                      }}
                    />
                  </Box>
                )}
                {filteredPrivatAccounts.length === 0 &&
                filteredOrganizationAccounts.length === 0 &&
                filteredMixedAccounts.length === 0 &&
                filterValue ? (
                  <Box
                    sx={{
                      width: '100%',
                      textAlign: 'left',
                      mt: 4,
                      px: 2,
                      py: 4,
                      background: (theme) => theme.palette.background.default,
                    }}
                  >
                    <Typography sx={{ mb: 2 }} variant='h5'>
                      Vi hittar inga konton
                    </Typography>
                    <SecondaryButton
                      onClick={() => {
                        setFilterValue('');
                      }}
                    >
                      Visa alla konton
                    </SecondaryButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      pt: 2,
                    }}
                  >
                    {filteredPrivatAccounts.length > 0 && (
                      <Box>
                        <Typography variant='subtitle1'>Personkonto</Typography>
                        <UserList
                          users={filteredPrivatAccounts}
                          selectedCustomer={selectedCustomer}
                          setSwitchingCustomer={setSwitchingCustomer}
                        />
                      </Box>
                    )}
                    {filteredOrganizationAccounts.length > 0 && (
                      <Box>
                        <Typography variant='subtitle1'>
                          Företagskonto
                        </Typography>
                        <UserList
                          users={filteredOrganizationAccounts}
                          selectedCustomer={selectedCustomer}
                          setSwitchingCustomer={setSwitchingCustomer}
                        />
                      </Box>
                    )}
                    {filteredMixedAccounts.length > 0 && (
                      <Box>
                        <Typography variant='subtitle1'>
                          Länkat konto
                        </Typography>
                        <UserList
                          users={filteredMixedAccounts}
                          selectedCustomer={selectedCustomer}
                          setSwitchingCustomer={setSwitchingCustomer}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>
        </CleanLayout>
      )}
    </>
  );
};

export default SwitchUser;
