export const PRIVATE_CUSTOMER_TAG = 'Tag_3';
export const COMPANY_CUSTOMER_TAG = 'Tag_4';

export const billingUnitTypes = [
  undefined, // 0
  'Measurement', // 1
  'Period', // 2
  'FixedUnit', // 3
];

export const invoiceDistributionTypes = [
  undefined, // 0
  'EDI', // = 1,
  'E-faktura', // = 2,
  'Mejl', // = 3,
  'SMS', // = 4,
  'Post', // = 5
];
export const utilityTypes = [
  'El', // 0
  'Elext', // 1
  'Fjv', // 2
  'Fjk', // 3
  'Va', // 4
  'Tvv', // 5
  'Bb', // 6
  'Et', // 7
  'Srv', // 8
  'Elprod', // 9
  'Umfjv', // 10
  'Umva', // 11
  undefined, // 12
  'Elprod', // 13 From elext, manually set
];

export const contractProductTypes = [
  'Undefined',
  'Fixed', // 1
  'Flex', // 2: Timmätt
  'Mix', // 3
  'Rolling', // 4
];

//['amount', 'price', 'quantity']
export const propTypeOptions = [
  {
    key: 'quantity',
    label: 'Förbrukning',
  },
  {
    key: 'amount',
    label: 'Belopp',
  },
  {
    key: 'price',
    label: 'Timpris',
  },
];
export const periodTypeOptions = [
  {
    key: 'annual',
    label: 'År',
    consumptionResolution: 1,
    unitOfTime: 'years',
    resolutionName: 'year',
  },
  {
    key: 'monthly',
    label: 'Månad',
    consumptionResolution: 2,
    unitOfTime: 'months',
    resolutionName: 'month',
  },

  // {
  //   key: 'week',
  //   label: 'Vecka'
  // },
  {
    key: 'daily',
    label: 'Dag',
    consumptionResolution: 4,
    unitOfTime: 'days',
    resolutionName: 'day',
  },
  {
    key: 'hourly',
    label: 'Timme',
    consumptionResolution: 8,
    unitOfTime: 'hours',
    resolutionName: 'hour',
  },
];

export const swedishMonthNames = [
  'januari',
  'februari',
  'mars',
  'april',
  'maj',
  'juni',
  'juli',
  'augusti',
  'september',
  'oktober',
  'november',
  'december',
];

export const swedishMonthNamesShort = [
  'jan',
  'feb',
  'mar',
  'apr',
  'maj',
  'jun',
  'jul',
  'aug',
  'sep',
  'okt',
  'nov',
  'dec',
];

export const NON_CUSTOMER_CUSTOMER_ID = 99999999999;
export const ALL_FACILITIES_GROUP_ID = '00000000-0000-0000-0000-000000000000';
