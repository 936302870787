import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const scrollPositions = useRef(new Map());

  useEffect(() => {
    // Restore scroll position if it exists, otherwise scroll to top
    if (scrollPositions.current.has(pathname)) {
      window.scrollTo(0, scrollPositions.current.get(pathname));
    } else {
      window.scrollTo(0, 0);
    }

    // Save scroll position before the component unmounts
    const handleScroll = () => {
      scrollPositions.current.set(pathname, window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
