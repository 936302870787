import Page from '@/layout/page/Page';
import Redirect from '@/pages/Redirect';
import { FlattenSpecialSeries } from '@/pages/infra-service/specialSeriesSlice';
import { InfraServiceName } from '@/pages/infra-service/types';
import NotFound from '@/pages/not-found/NotFound';
import { Account, Customer } from '@/types/Customer';
import { InfraService } from '@/types/InfraService';
import { State } from '@/types/State';
import { find, get, map, spread, union } from 'lodash';
import { ReactNode, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import { createTheme } from 'ui';
import {
  IconAcUnit,
  IconBarChart,
  IconBathtub,
  IconBolt,
  IconDashboard,
  IconDescription,
  IconHistoryEdu,
  IconInvertColors,
  IconListAlt,
  IconWhatshot,
} from 'ui/Icons';
import ProgressLoading from 'ui/components/ProgressLoading';
import { getSelectedCustomer } from '../../app/slices/userSlice';
import { lazyWithRetry as lazy } from '../../helpers/lazyWithRetry';
import {
  customerIsCompany,
  getInfraServiceFromFacility,
  hasProduction,
  isReadingTypeManual,
  propertyHasFjvServiceAgreement,
  userHasFJVEffekttariff,
  userHasFlowCharge,
  userIsCustomer,
  userIsInactive,
  userIsIndividual,
  userIsUpcoming,
} from '../../helpers/utils';
import { setRoutes } from './routesSlice';

const RouteComponents: {
  [key: string]: React.LazyExoticComponent<React.ComponentType>;
} = {
  NonCustomer: lazy(() => import('../../pages/non-customer/NonCustomer')),
  Landing: lazy(() => import('../../pages/landing/Landing')),
  Invoices: lazy(() => import('../../pages/invoices/Invoices')),
  Spotpriser: lazy(() => import('../../pages/spotpriser/Spotpriser')),
  InvoiceReference: lazy(
    () => import('../../pages/invoice-reference/InvoiceReference'),
  ),
  ElectricityMeterSwitch: lazy(
    () => import('../../pages/electricity-meter-switch/ElectricityMeterSwitch'),
  ),
  InfraService: lazy(() => import('../../pages/infra-service/InfraService')),
  Cooling: lazy(() => import('../../pages/infra-service/Cooling')),
  FlowCharge: lazy(() => import('../../pages/infra-service/FlowCharge')),
  FJVEnergy: lazy(() => import('../../pages/infra-service/FJVEnergy')),
  ManageHanPort: lazy(() => import('../../pages/manage-han-port')),
  HighVoltage: lazy(() => import('../../pages/infra-service/HighVoltage')),
  MeterReadings: lazy(() => import('../../pages/meter-readings/MeterReadings')),
  DistanceHeatingContract: lazy(
    () =>
      import('../../pages/distance-heating-contract/DistanceHeatingContract'),
  ),
  UtjamnadBetalning: lazy(
    () => import('../../pages/utjamnad-betalning/UtjamnadBetalning'),
  ),
  Users: lazy(() => import('../../pages/users/Users')),
  Account: lazy(() => import('../../pages/account/Account')),
  Contracts: lazy(() => import('../../pages/contracts/Contracts')),
  ConfirmAccount: lazy(
    () => import('../../pages/confirm-account/ConfirmAccount'),
  ),
  Reports: lazy(() => import('../../pages/reports/Reports')),
  AddReport: lazy(() => import('../../pages/reports/AddReport')),
  Messages: lazy(() => import('../../pages/messages/Messages')),
  DisruptionInformation: lazy(
    () => import('../../pages/disruption-information/DisruptionInformation'),
  ),
};

let _routes: RouteType[] = [];

const theme = createTheme();

const Routes = () => {
  const dispatch = useDispatch();
  const { selectedProperty, account } = useSelector(
    (state: State) => state.user,
  );
  const { routes } = useSelector((state: State) => state.routes);
  const selectedCustomer = useSelector((state: State) =>
    getSelectedCustomer(state),
  );
  const specialSeries = useSelector(
    (state: State) => state?.specialSeries?.data,
  );
  const authProfile = useSelector((state: State) => state?.auth?.user?.profile);
  const hasEnergySwitchBooking = useSelector((state: State) => {
    if (!state?.electricityMeterSwitch) {
      return false;
    }

    return (!!state?.electricityMeterSwitch?.bookedTimes &&
      state?.electricityMeterSwitch?.bookedTimes?.length > 0 &&
      state?.electricityMeterSwitch?.bookedTimes?.find(
        (bookedTime) => new Date(bookedTime?.time) > new Date(),
      )) as unknown as boolean;
  });

  const hasManualDrainReading =
    selectedProperty &&
    isReadingTypeManual(getInfraServiceFromFacility(selectedProperty, 'VA'));

  const mRoutes = spread(union)(
    map(routes, (route) => {
      return route.childRoutes
        ? [
            ...map(route.childRoutes, (c) => ({ ...c, isChildRoute: true })),
            route,
          ]
        : [route];
    }),
  );

  const setRoutesInStore = () => {
    _routes = getRoutes({
      selectedCustomer,
      selectedProperty,
      account,
      hasEnergySwitchBooking,
      hasManualDrainReading,
      authProfile,
      specialSeries,
    });
    dispatch(setRoutes(_routes));
  };

  useEffect(setRoutesInStore, [
    selectedCustomer,
    selectedProperty,
    hasEnergySwitchBooking,
    hasManualDrainReading,
    account,
    authProfile,
    dispatch,
    specialSeries,
  ]);

  return selectedCustomer && selectedProperty ? (
    <RouterRoutes>
      {map(mRoutes, (route: any, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <Page key={route.path} route={route}>
              {routeChecker(route, routes, {
                selectedProperty: selectedProperty,
              })}
            </Page>
          }
        />
      ))}
      <Route path='*' element={<NotFound />} />
    </RouterRoutes>
  ) : null;
};

export default Routes;

type RouteSection = 'overview' | 'services' | 'tools' | null;

export type RouteType = {
  path: string;
  redirect?: string;
  component?: string;
  title?: string | ReactNode;
  color?: string;
  icon?: ReactNode;
  section?: RouteSection | null;
  pageHeaderOptions?: {
    hide?: boolean;
    title?: string;
    subtitle?: string;
    hideProperty?: boolean;
    propertyText?: string;
    backHref?: string;
    backText?: string;
  };
  pageOptions?: {
    hideHero?: boolean;
  };
  childRoutes?: RouteType[];
  utilityType?: string[];
  infraService?: string;
  hidden?: boolean;
  exact?: boolean;
};

type GetRoutesProps = {
  selectedCustomer: Customer | null;
  selectedProperty: any;
  account: Account;
  hasEnergySwitchBooking: boolean;
  hasManualDrainReading: boolean;
  authProfile: any;
  specialSeries: FlattenSpecialSeries | null;
};

export const getRoutes = ({
  selectedCustomer,
  selectedProperty,
  account,
  hasEnergySwitchBooking,
  hasManualDrainReading,
  specialSeries,
}: GetRoutesProps) => {
  const userRoutes: RouteType[] = [
    {
      path: '/non-customer',
      component: 'NonCustomer',
      title: 'Översikt',
      icon: <IconDashboard />,
      color: 'primary',
      section:
        !userIsCustomer(selectedCustomer) && !userIsUpcoming(selectedCustomer)
          ? 'overview'
          : null,
      pageHeaderOptions: {
        hide: true,
      },
    },
    {
      path: '/',
      component: 'Landing',
      exact: true,
      icon: <IconDashboard />,
      title: 'Översikt',
      color: 'primary',
      section:
        selectedCustomer &&
        (userIsCustomer(selectedCustomer) || userIsUpcoming(selectedCustomer))
          ? 'overview'
          : null,
      pageHeaderOptions: {
        title: 'Översikt',
      },
    },
    {
      path: '/fjarrvarme',
      component: 'InfraService',
      icon: <IconWhatshot />,
      title: 'Fjärrvärme',
      color: 'yellow',
      section: 'services',
      utilityType: ['FJV'],
      infraService: 'FJV',
      pageHeaderOptions: {
        hideProperty: true,
      },
      childRoutes: [
        {
          path: '/fjarrvarme/avtal',
          component: 'DistanceHeatingContract',
          title: `Teckna ${
            userIsIndividual(selectedCustomer) ? 'serviceavtal' : 'driftavtal'
          }`,
          hidden: userIsIndividual(selectedCustomer)
            ? propertyHasFjvServiceAgreement(selectedProperty)
            : false,
          pageHeaderOptions: {
            subtitle: userIsIndividual(selectedCustomer)
              ? 'Skicka in en ansökan så kontaktar vid dig inom kort angående ditt serviceavtal.'
              : 'Fjärrvärme är marknadens mest pålitliga värmekälla med en otroligt hög leveranssäkerhet men fjärrvärmecentralen består trots allt av en mängd tekniska komponenter som behöver ses över och underhållas. Detta kan delas mellan oss eller så tar vi hand om allt inklusive risker, beroende på hur enkelt just du vill ha det.',
          },
        },
        {
          path: '/fjarrvarme/avkylning',
          component: 'Cooling',
          title: `Avkylning`,
          hidden: !customerIsCompany(selectedCustomer),
          pageHeaderOptions: {
            backHref: '/fjarrvarme',
            backText: 'Fjärrvärme',
            subtitle:
              'Nyttjar din anläggning värmen i vattenflödet på ett effektivt sätt kan du få en flödespremie (gäller oktober till april). Mer om flödespremie på malarenergi.se',
          },
        },
        {
          path: '/fjarrvarme/flodespremie',
          component: 'FlowCharge',
          title: `Flödespremie`,
          hidden: !userHasFlowCharge(selectedCustomer),
          pageHeaderOptions: {
            backHref: '/fjarrvarme',
            backText: 'Fjärrvärme',
            subtitle: `Nyttjar din anläggning värmen i vattenflödet på ett effektivt sätt kan du få en flödespremie (gäller oktober till april). Mer om flödespremie på malarenergi.se`,
          },
        },
        {
          path: '/fjarrvarme/bas-och-spetsenergi',
          component: 'FJVEnergy',
          title: `Bas- och spetsenergi`,
          hidden: !userHasFJVEffekttariff(selectedCustomer),
          pageHeaderOptions: {
            backHref: '/fjarrvarme',
            backText: 'Fjärrvärme',
            subtitle: 'Analysera din bas- samt spetsenergi.',
          },
        },
      ],
    },

    {
      path: '/spotpriser',
      icon: <IconBarChart />,
      component: 'Spotpriser',
      section: 'tools',
      title: 'Spotpriset',
      hidden:
        !userIsCustomer(selectedCustomer) && !userIsUpcoming(selectedCustomer),
      pageHeaderOptions: {
        subtitle:
          'Spotpriset utgör grunden i det rörliga elpriset och visas i öre/kWh. Perfekt för dig som vill påverka din elkostnad genom att använda el de timmar det är billigare.',
      },
    },

    {
      path: '/undermatning-fjarrvarme',
      component: 'InfraService',
      icon: <IconWhatshot />,
      title: 'Undermätning fjärrvärme',
      color: 'yellow',
      section: 'services',
      utilityType: ['Umfjv'],
      infraService: 'UMFJV',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },
    {
      path: '/el',
      component: 'InfraService',
      icon: <IconBolt />,
      title: 'El',
      color: 'greenLight',
      section: 'services',
      utilityType: ['EL, ELPROD'],
      infraService: 'EL',
      pageHeaderOptions: {
        hideProperty: true,
      },
      childRoutes: [
        {
          path: '/el/eloverskott',
          component: 'InfraService',
          utilityType: ['ELPROD'],
          infraService: 'ELPROD',
          title: 'Elöverskott',
          hidden: !hasProduction(
            selectedProperty?.infraServices?.find(
              (infraService: InfraService) => infraService?.name === 'EL',
            ),
          ),
        },
        {
          path: '/el/han-port',
          component: 'ManageHanPort',
          title: 'HAN-port',
          pageHeaderOptions: {
            hideProperty: true,
          },
          hidden: !selectedCustomer?.utilities?.some(
            (utility) => utility.name === ('EL' satisfies InfraServiceName),
          ),
        },
        {
          path: '/el/elmatarbyte',
          component: 'ElectricityMeterSwitch',
          title: 'Elmätarbyte',
          hidden: !hasEnergySwitchBooking,
        },
        {
          path: '/el/hogspanning',
          component: 'HighVoltage',
          title: 'Högspänning',
          hidden: specialSeries === null || specialSeries?.length === 0,
          pageHeaderOptions: {
            hideProperty: true,
          },
        },
      ],
    },
    {
      path: '/undermatning-el',
      component: 'InfraService',
      icon: <IconBolt />,
      title: 'Undermätning el',
      color: 'greenLight',
      section: 'services',
      utilityType: ['IMDEL'],
      infraService: 'IMDEL',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },
    {
      path: '/vatten-avlopp',
      component: 'InfraService',
      icon: <IconInvertColors />,
      title: 'Vatten & Avlopp',
      color: 'turquoise',
      section: 'services',
      utilityType: ['Va'],
      infraService: 'VA',
      pageHeaderOptions: {
        hideProperty: true,
      },
      childRoutes: [
        {
          path: '/vatten-avlopp/avlasning',
          component: 'MeterReadings',
          title: 'Vattenavläsning',
          pageHeaderOptions: {
            title: hasManualDrainReading
              ? 'Lämna mätarställning'
              : 'Vattenavläsning',
            subtitle: hasManualDrainReading
              ? 'Här kan du enkelt lämna mätarställning på din vattenmätare.'
              : undefined,
          },
        },
      ],
    },
    {
      path: '/undermatning-vatten',
      component: 'InfraService',
      icon: <IconInvertColors />,
      title: 'Undermätning Vatten',
      color: 'turquoise',
      section: 'services',
      utilityType: ['Umva'],
      infraService: 'UMVA',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },
    {
      path: '/tappvarmvatten',
      component: 'InfraService',
      icon: <IconBathtub />,
      title: 'Tappvarmvatten',
      color: 'redLight',
      section: 'services',
      utilityType: ['Tvv'],
      infraService: 'TVV',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },

    {
      path: '/fjarrkyla',
      component: 'InfraService',
      icon: <IconAcUnit />,
      title: 'Fjärrkyla',
      color: 'oceanGreen',
      section: 'services',
      utilityType: ['Fjk'],
      infraService: 'FJK',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },

    {
      path: '/users',
      component: 'Users',
      title: 'Användare',
      pageHeaderOptions: {
        hideProperty: true,
        propertyText: selectedCustomer?.name || account?.displayName,
        subtitle:
          'Här visas alla användare som har tillgång till kontot, förutom admin.',
      },
    },
    {
      path: '/avisering-vid-avbrott',
      component: 'DisruptionInformation',
      title: 'Avisering vid avbrott', //'Mottagare av avbrotts\u00ADinformation',
      pageHeaderOptions: {
        hideProperty: true,
        subtitle:
          'Här visas vilka mottagare som informeras när det pågår avbrott eller driftstörningar som kan påverka kontots tjänster.',
      },
    },
    {
      path: '/account',
      redirect: '/kontaktuppgifter',
    },
    {
      path: '/kontaktuppgifter',
      component: 'Account',
      title: 'Kontaktuppgifter',
      pageHeaderOptions: {
        hideProperty: true,
        subtitle:
          selectedCustomer?.identificationNumber ||
          account?.identificationNumber,
      },
    },

    {
      path: '/fakturor',
      component: 'Invoices',
      icon: <IconDescription />,
      title: 'Fakturor',
      color: 'orange',
      section:
        userIsCustomer(selectedCustomer) || userIsInactive(selectedCustomer)
          ? 'tools'
          : null,
      childRoutes: [
        {
          path: '/andra-fakturareferens/',
          component: 'InvoiceReference',
          title: 'Ändra fakturareferens',
          hidden: userIsIndividual(selectedCustomer),
          pageHeaderOptions: {
            hideProperty: true,
          },
        },
      ],
      pageHeaderOptions: {
        hideProperty: false,
        subtitle: 'Här hittar du alla dina fakturor.',
      },
    },
    {
      path: '/teckna/utjamnad-betalning',
      component: 'UtjamnadBetalning',
      title: 'Utjämnad betalning',
      pageHeaderOptions: {
        hideProperty: true,
        backHref: '/fakturor',
        backText: 'Fakturor',
        subtitle:
          'Utjämnad betalning passar dig som vill fördela dina kostnader jämnt över hela året. Du får en jämnare spridning på dina betalningar till oss genom att vi delar upp din beräknade årskostnad i tolv lika stora delar. Om din användning eller dina kostnader ökar eller minskar, justeras månadsbeloppet. Justeringen sker minst en gång per år. Avgiften för tjänsten Utjämnad betalning är 19 kronor/månad.',
      },
    },
    {
      path: '/bekrafta_konto/:code',
      component: 'ConfirmAccount',
      title: 'Bekräfta inbjudan',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },
    {
      path: '/arenden',
      component: 'Messages',
      title: 'Ärenden',
      pageHeaderOptions: {
        hideProperty: true,
      },
    },
  ];

  const companyRoutes: RouteType[] = [
    {
      path: '/avtal',
      component: 'Contracts',
      icon: <IconHistoryEdu />,
      title: 'Avtal',
      section:
        userIsCustomer(selectedCustomer) || userIsUpcoming(selectedCustomer)
          ? 'tools'
          : null,
      color: 'orange',
      pageHeaderOptions: {
        subtitle: 'Här hittar du alla dina avtal.',
      },
    },
    {
      path: '/rapporter',
      component: 'Reports',
      icon: <IconListAlt />,
      title: 'Rapporter',
      section: userIsCustomer(selectedCustomer) ? 'tools' : null,
      color: 'orange',
      pageHeaderOptions: {
        hideProperty: true,
      },
      childRoutes: [
        {
          path: '/rapporter/skapa-ny',
          component: 'AddReport',
          icon: <IconListAlt />,
          title: 'Skapa ny rapport',
          hidden: true,
          section: userIsCustomer(selectedCustomer) ? 'tools' : null,
          color: 'orange',
          pageHeaderOptions: {
            hideProperty: true,
            backHref: '/rapporter',
            backText: 'Rapporter',
          },
        },
      ],
    },
  ];

  return [...userRoutes, ...companyRoutes];
};

const routeChecker = (route: RouteType, routes: RouteType[], props: any) => {
  if (route?.redirect) {
    return <Redirect to={route.redirect} />;
  }
  if (!route?.component) {
    return false;
  }
  const Component = RouteComponents[route.component];
  if (route.section) {
    // Parent route
    if (route.infraService) {
      // Parent that needs checking
      const infraService = find(
        props.selectedProperty.infraServices,
        (utility) => {
          return route.infraService === utility.name;
        },
      );
      return infraService ? (
        <Suspense fallback={<ProgressLoading />}>
          <Component
            key={infraService.id}
            infraService={infraService}
            {...props}
          />
        </Suspense>
      ) : (
        <div className='simple-box p-5'>
          Du saknar avtal för {route.title} för den här fastigheten.
        </div>
      );
    } else {
      return (
        <Suspense fallback={<ProgressLoading />}>
          <Component {...props} />
        </Suspense>
      );
    }
  } else {
    // Child route
    const parentRoute: RouteType | undefined = find(routes, (r) => {
      return r.path === `/${route.path.split('/')[1]}`;
    });

    if (route.infraService === 'ELPROD') {
      const infraService = find(
        props.selectedProperty.infraServices,
        (utility) => {
          return hasProduction(utility) ? utility : false;
        },
      );

      return infraService ? (
        <Suspense fallback={<ProgressLoading />}>
          <Component
            key='ELPROD'
            infraService={infraService}
            isProduction={true}
            {...props}
          />
        </Suspense>
      ) : (
        <div className='simple-box p-5'>
          Du saknar avtal för elöverskott för den här fastigheten.
        </div>
      );
    }

    if (parentRoute && parentRoute.infraService) {
      const infraService = find(
        props.selectedProperty.infraServices,
        (utility) => {
          return utility?.name && parentRoute?.infraService
            ? parentRoute?.infraService?.indexOf(utility?.name) > -1
            : false;
        },
      );

      return infraService ? (
        <Suspense fallback={<ProgressLoading />}>
          <Component
            key={infraService.name}
            infraService={infraService}
            {...props}
          />
        </Suspense>
      ) : (
        <div className='simple-box p-5'>
          Du saknar avtal för {parentRoute.title} för den här fastigheten.
        </div>
      );
    }

    return (
      <Suspense fallback={<ProgressLoading />}>
        <Component {...props} />
      </Suspense>
    );
  }
};

export const getRoutePath = (
  utilityTypeName: string,
  meteringPointTypeName: string,
) => {
  // make this more generic later
  if (utilityTypeName === 'EL' && meteringPointTypeName === 'PRODUCTION') {
    return '/el/eloverskott';
  }

  return get(
    find(
      _routes,
      (route) =>
        route.infraService && route.infraService.indexOf(utilityTypeName) > -1,
    ),
    'path',
  );
};

export const getMainColor = () => {
  return theme.palette.primary.main;
};
