import {
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { forwardRef } from "react";

const StyledLink = styled(MuiTextField)`
  margin-bottom: 1rem;
`;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref) => <StyledLink ref={ref} {...props} />
);

TextField.displayName = "TextField";

export default TextField as typeof MuiTextField;
