import { Theme } from "@mui/material";
import { NavigatorOptions, ScrollbarOptions } from "highcharts/highstock";
import { createTheme } from "../../theme/theme";
import { hexToRgba } from "../../utils/color";
import { months, monthsShort, weekDaysShort } from "../../utils/date";

const theme = createTheme();

export const ChartNavigatorDefaults = {
  height: 55,
  margin: 0,
  handles: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.text.secondary,
    height: 20,
    width: 15,
  },
  xAxis: {
    gridLineColor: theme.palette.text.secondary,
    tickPixelInterval: 100,
    labels: {
      style: {
        color: theme.palette.text.secondary,
        fontSize: "12px",
      },
    },
    tickColor: theme.palette.text.secondary,
    tickLength: 38,
  },
} as NavigatorOptions;

export const getChartDefaults = (theme: Theme) => {
  return {
    title: undefined,
    tooltip: {
      shared: true,
      split: false,
      enabled: true,
      formatter: function () {
        return false;
      },
    },
    lang: {
      shortMonths: monthsShort,
      shortWeekdays: weekDaysShort,
      months: months,
      resetZoom: "Återställ zoom",
      loading: "Laddar...",
    },
    time: {
      useUTC: false,
      timezone: "europe/stockholm",
    },
    xPlotlineStyle: {
      id: "crosshair",
      color: hexToRgba(theme.palette.action.active, 0.54),
      width: 1,
      zIndex: 10,
      dashStyle: "Solid",
    } as Highcharts.XAxisPlotLinesOptions,
  };
};

export const ChartScrollbarDefaults = {
  enabled: true,
  height: 35,
  margin: 0,
  zIndex: 2,
  buttonArrowColor: theme.palette.background.paper,
  buttonBackgroundColor: theme.palette.primary.main,
  buttonBorderColor: theme.palette.primary.light,
  rifleColor: theme.palette.background.paper,
  barBackgroundColor: theme.palette.primary.main,
  barBorderColor: theme.palette.text.primary,
  trackBackgroundColor: hexToRgba(theme.palette.primary.main, 0.2),
  trackBorderColor: theme.palette.primary.light,
} as ScrollbarOptions;

export const ChartShortMonths = [
  "jan",
  "feb",
  "mar",
  "apr",
  "maj",
  "jun",
  "jul",
  "aug",
  "sep",
  "okt",
  "nov",
  "dec",
];

export const ChartPlotOptionsDefaults = {
  series: {
    borderWidth: 0,
    borderRadius: 4,
    states: {
      select: {
        color: theme.palette.secondary.light,
      },
    },
  },
  bar: {
    borderRadius: 4,
  },
};

export const ChartTooltipDefaults = {
  outside: false,
  borderRadius: 14,
  shared: true,
  useHTML: true,
};
