import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  styled,
  ToggleButtonGroupProps,
} from "@mui/material";
import { buttonRadius } from "../../theme/theme";
import { hexToRgba } from "../../utils/color";

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)`
  border-radius: ${buttonRadius};
  background: ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.08)};
`;

const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  return (
    <StyledToggleButtonGroup {...props}>
      {props.children}
    </StyledToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
