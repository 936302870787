import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useEffect, useMemo } from "react";
import { createTheme } from "./theme";

type ThemeProviderProps = {
  children: React.ReactNode;
  darkMode?: boolean;
  baseUrl?: string;
};

const ThemeProvider = ({ children, darkMode, baseUrl }: ThemeProviderProps) => {
  const theme = useMemo(
    () => createTheme(darkMode, baseUrl),
    [baseUrl, darkMode]
  );

  useEffect(() => {
    document.documentElement.style.setProperty(
      "color-scheme",
      darkMode ? "dark" : "light"
    );
  }, [darkMode]);

  return (
    <EmotionThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </EmotionThemeProvider>
  );
};

export default ThemeProvider;
