import {
  FormControlLabel as MuiFormControlLabel,
  styled,
  FormControlLabelProps,
} from "@mui/material";

const StyledFormControlLabel = styled(MuiFormControlLabel)`
  margin-bottom: 0.5rem;
`;

const FormControlLabel = (props: FormControlLabelProps) => (
  <StyledFormControlLabel {...props} />
);

export default FormControlLabel;
