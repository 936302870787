import { Slider as MuiSlider, SliderProps, styled } from "@mui/material";
import React from "react";

const GeneralSlider = styled(MuiSlider)`
  & .MuiSlider-mark {
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
`;

const PrimarySlider = styled(GeneralSlider)`
  & > .MuiSlider-rail {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
  &:not(.Mui-disabled) > .MuiSlider-rail {
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  & > .MuiSlider-thumb > .MuiSlider-valueLabel {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SecondarySlider = styled(GeneralSlider)`
  & > .MuiSlider-rail {
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }
  &:not(.Mui-disabled) > .MuiSlider-rail {
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
  & > .MuiSlider-thumb > .MuiSlider-valueLabel {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Slider = (props: SliderProps) => {
  const { color = "primary", ...otherProps } = props;
  return color === "primary" ? (
    <PrimarySlider {...otherProps} />
  ) : (
    <SecondarySlider {...otherProps} />
  );
};

export default Slider;
