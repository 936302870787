import { Box, CircularProgress, Typography } from "@mui/material";

type ProgressLoadingProps = {
  statusLabel?: string;
};

const ProgressLoading = ({
  statusLabel = "Laddar...",
}: ProgressLoadingProps) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        pt: 4,
        position: "relative",
      }}
    >
      <CircularProgress />
      <Typography variant="h5" sx={{ mt: 2 }}>
        {statusLabel}
      </Typography>
    </Box>
  );
};

export default ProgressLoading;
