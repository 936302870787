import { TextFieldProps } from "@mui/material";
import { IMaskMixin } from "react-imask";
import { IMaskInputProps, IMaskMixinProps } from "react-imask/dist/mixin";
import TextField from "./TextField";

// There is a bug with type inference here, remove the `FC` annotation
// and you will see the error. If not, then the bug is hopefully fixed.
const MaskedTextField: any = IMaskMixin(
  ({
    inputRef,
    ...rest
  }: TextFieldProps & IMaskMixinProps & IMaskInputProps) => (
    <TextField inputRef={inputRef} {...rest} />
  )
);

export default MaskedTextField;
