import { Box, LinearProgress, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import { lightModeColors } from "../../theme/theme";

type PageLoaderProps = {
  title?: string;
};

const PageLoader = ({ title }: PageLoaderProps) => {
  return (
    <Box
      sx={{
        background: lightModeColors.primary.dark,
        textAlign: "center",
        height: "100vh",
        width: "100vw",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Logo textColor="#fff" size={140} />
      <Box sx={{ height: 20, pt: "20px", pb: "24px" }}>
        <LinearProgress color="primary" sx={{ width: "150px", mx: "auto" }} />
      </Box>
      <Typography variant="h5" color="white" sx={{ px: 2 }}>
        {title || "Laddar..."}
      </Typography>
    </Box>
  );
};

export default PageLoader;
