import { RouteType } from '@/features/routes/Routes';
import { ElementType } from 'react';
import { Link } from 'react-router-dom';
import BorderHeading from 'ui/components/BorderHeading';

type PageHeadingProps = {
  route: Omit<RouteType, 'path'> & {
    path?: string;
  };
  component?: ElementType;
};

const PageHeading = ({ route, component }: PageHeadingProps) => {
  const { pageHeaderOptions, title } = route;
  const hidden = pageHeaderOptions && pageHeaderOptions.hide;
  const mTitle = (pageHeaderOptions && pageHeaderOptions.title) || title;
  const mSubtitle = pageHeaderOptions && pageHeaderOptions.subtitle;
  const backHref = pageHeaderOptions && pageHeaderOptions.backHref;
  const backText = pageHeaderOptions && pageHeaderOptions.backText;

  if (hidden) {
    return null;
  }
  return (
    <BorderHeading
      title={mTitle}
      overline={backText && backHref ? backText : undefined}
      subtitle={mSubtitle}
      component={component}
      backHref={backHref}
      linkComponent={Link}
    />
  );
};

export default PageHeading;
