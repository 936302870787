import { ReportConfiguration, ReportOptions, State } from '@/types/State';
import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { each, filter, includes } from 'lodash';
import dayjs from 'dayjs';
import { apiClientV2 } from '../../app/axios.config';
import { getSelectedCustomer } from '../../app/slices/userSlice';

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    reportConfigurations: {
      items: [],
      page: 0,
      pageSize: 25,
      totalCount: 0,
      totalPages: -1,
    },
    reportTemplates: {},
    reportOptions: {
      name: '',
      fromDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
      utilityCategoryId: null,
      resolutionTypeId: 2,
      rollingTimeSpan: null,
      rollingTimeSpanValue: null,
      selectedFacilityIds: [],
      meteringPointTypeId: null,
    },
  },
  reducers: {
    setReportConfigurations: (state, action) => {
      state.reportConfigurations = action.payload;
    },
    setReportTemplates: (state, action) => {
      state.reportTemplates = action.payload;
    },
    setReportOptions: (state, action) => {
      if (
        action.payload.rollingTimeSpan &&
        state.reportOptions.resolutionTypeId !== action.payload.resolutionTypeId
      ) {
        state.reportOptions = {
          ...action.payload,
          rollingTimeSpanValue: includes(
            [1, 2],
            parseInt(action.payload.resolutionTypeId),
          )
            ? '12'
            : '1',
        };
      } else {
        state.reportOptions = action.payload;
      }
    },
  },
});

export const { setReportConfigurations, setReportTemplates, setReportOptions } =
  reportsSlice.actions;

export const fetchReportConfigurations =
  () => async (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());

    const response = await apiClientV2.get(
      `customers/${selectedCustomer?.customerId}/reportconfigurations`,
      {
        params: {
          pageSize: 10000,
          customerAccountId: selectedCustomer?.customerAccountId,
        },
      },
    );
    const data = response?.data?.data[0];
    each(data?.items, (item) => {
      item.options = JSON.parse(item.options);
    });
    return dispatch(setReportConfigurations(data));
  };

export const fetchReportConfiguration =
  (reportConfigurationId: string) =>
  (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());
    return apiClientV2.get(
      `customers/${selectedCustomer?.customerId}/reportconfigurations/${reportConfigurationId}`,
    );
  };

export const createReportConfiguration =
  (configuration: ReportConfiguration) =>
  (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());
    return apiClientV2.post(
      `customers/${selectedCustomer?.customerId}/reportconfigurations`,
      configuration,
    );
  };

export const updateReportConfiguration =
  (configuration: ReportConfiguration, reportConfigurationId: string) =>
  (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());
    return apiClientV2.put(
      `customers/${selectedCustomer?.customerId}/reportconfigurations/${reportConfigurationId}`,
      configuration,
    );
  };

export const deleteReportConfiguration =
  (reportConfigurationId: string) =>
  (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());
    return apiClientV2
      .delete(
        `customers/${selectedCustomer?.customerId}/reportconfigurations/${reportConfigurationId}`,
      )
      .then(() => {
        const newItems = filter(
          getState().reports.reportConfigurations.items,
          (item) => item.id !== reportConfigurationId,
        );
        return dispatch(
          setReportConfigurations({
            ...getState().reports.reportConfigurations,
            items: newItems,
            totalCount: getState().reports.reportConfigurations.totalCount - 1,
          }),
        );
      });
  };

export const fetchReportTemplates =
  () => (dispatch: Dispatch, getState: () => State) => {
    const selectedCustomer = getSelectedCustomer(getState());

    return apiClientV2
      .get(`customers/${selectedCustomer?.customerId}/reporttemplates`, {
        params: {
          customerAccountId: selectedCustomer?.customerAccountId,
        },
      })
      .then((response) => response?.data?.data[0])
      .then((data) => dispatch(setReportTemplates(data)));
  };

export const updateReportOptions =
  (options: ReportOptions) => (dispatch: Dispatch) =>
    new Promise<void>((resolve) => {
      dispatch(setReportOptions(options));
      resolve();
    });

export default reportsSlice.reducer;
