import { apiClientV2 } from '@/app/axios.config';
import { SpecialSeriesData } from '@/types/State';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SpecialSeries = {
  data: null,
  loading: false,
  error: null,
};

type PayloadData = {
  data: [SpecialSeriesData[]];
};

export type SpecialSeries = {
  data: FlattenSpecialSeries | null;
  loading: boolean;
  error: string | null;
};

export type FlattenSpecialSeries = {
  description: string;
  name: string;
  unit: string;
  timeSeriesId: number;
}[];

const specialSeriesSlice = createSlice({
  name: 'specialSeries',
  initialState,
  reducers: {
    getSpecialSeriesStart(state) {
      state.loading = true;
      state.error = null;
    },
    getSpecialSeriesSuccess(state, action: PayloadAction<PayloadData>) {
      state.data =
        action.payload?.data?.[0]?.map((data) => data.timeSeries)?.flat() ??
        null;
      state.loading = false;
      state.error = null;
    },
    getSpecialSeriesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getSpecialSeriesStart,
  getSpecialSeriesSuccess,
  getSpecialSeriesFailure,
} = specialSeriesSlice.actions;

export const fetchSpecialSeries =
  (customerId: string) => async (dispatch: any) => {
    try {
      dispatch(getSpecialSeriesStart());
      const response = await apiClientV2(
        `/customers/${customerId}/specialtimeseries`
      );
      dispatch(getSpecialSeriesSuccess(response.data));
    } catch (error) {
      dispatch(
        getSpecialSeriesFailure(
          'Något gick fel vid uthämtning av specialserier'
        )
      );
    }
  };

export default specialSeriesSlice.reducer;
