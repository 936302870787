import { cloneElement } from 'react';
import { Box } from 'ui/mui';
import PageHeading from './PageHeading';

const Page = ({ ...props }) => {
  return (
    <>
      <Box>
        <PageHeading route={props.route} />
        {cloneElement(props.children)}
      </Box>
    </>
  );
};

export default Page;
