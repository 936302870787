import Logo from 'ui/components/logo/Logo';
import { Box } from 'ui/mui';

const Header = () => {
  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'flex-end',
        pr: 2,
      }}
    >
      <Logo size={64} />
    </Box>
  );
};

export default Header;
