import Poller from '@/components/poller/Poller';
import { State } from '@/types/State';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IconAccountCircle, IconClose, IconMenu } from 'ui/Icons';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Container,
  Toolbar,
} from 'ui/mui';
import { getSelectedCustomer } from '../app/slices/userSlice';
import Routes from '../features/routes/Routes';
import { nackaUser, userIsCustomer } from '../helpers/utils';
import { fetchEnergySwitchBooking } from '../pages/electricity-meter-switch/meterSwitchSlice';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const drawerWidth = 260;
export const bottomNavigationHeight = 76;

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedProperty } = useSelector((state: State) => state.user);
  const selectedCustomer = useSelector((state: State) =>
    getSelectedCustomer(state),
  );
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openMobileProfile, setOpenMobileProfile] = useState(false);

  const [bottomNavigationValue, setBottomNavigationValue] = useState(null);

  const handlePropertyChange = useCallback(() => {
    if (
      selectedProperty &&
      userIsCustomer(selectedCustomer) &&
      !nackaUser(selectedCustomer)
    ) {
      dispatch(fetchEnergySwitchBooking() as any);
    }
  }, [dispatch, selectedCustomer, selectedProperty]);

  // When selectedProperty changes
  useEffect(() => {
    handlePropertyChange();
  }, [handlePropertyChange, selectedProperty]);

  useEffect(() => {
    setOpenMobileMenu(false);
    setOpenMobileProfile(false);
    setBottomNavigationValue(null);
  }, [location, setOpenMobileMenu, setOpenMobileProfile]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Poller />
      <BottomNavigation
        showLabels
        sx={{
          position: 'fixed',
          justifyContent: 'space-between',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          display: { xs: 'flex', md: 'none' },
          borderTop: '1px solid',
          borderTopColor: 'divider',
          alignItems: 'start',
          height: bottomNavigationHeight,
        }}
        value={bottomNavigationValue}
        onChange={(_, newValue) => {
          if (newValue === 'account') {
            setOpenMobileMenu(false);
            setBottomNavigationValue(!openMobileProfile ? newValue : null);
            setOpenMobileProfile(!openMobileProfile);
          } else if (newValue === 'menu') {
            setOpenMobileProfile(false);
            setBottomNavigationValue(!openMobileMenu ? newValue : null);
            setOpenMobileMenu(!openMobileMenu);
          }
        }}
      >
        <BottomNavigationAction
          sx={{
            textTransform: 'capitalize',
            maxWidth: '50%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            backgroundColor: (theme) => theme.palette.background.paper,
            overflow: 'hidden',
            borderTop: '3px solid transparent',
            '&.Mui-selected': {
              borderTopColor: (theme) => theme.palette.secondary.main,
              color: (theme) => theme.palette.secondary.main,
            },
            pt: 0.9,
            pb: 3,
          }}
          value={'account'}
          label={(
            selectedCustomer?.displayName || selectedCustomer?.name
          )?.toLocaleLowerCase()}
          icon={<IconAccountCircle />}
        />
        <BottomNavigationAction
          sx={{
            maxWidth: '50%',
            pt: 0.9,
            pb: 3,
            backgroundColor: (theme) => theme.palette.background.paper,
            borderTop: '3px solid transparent',
            '&.Mui-selected': {
              borderTopColor: (theme) => theme.palette.secondary.main,
              color: (theme) => theme.palette.secondary.main,
            },
          }}
          label='Meny'
          value={'menu'}
          icon={openMobileMenu ? <IconClose /> : <IconMenu />}
        />
      </BottomNavigation>

      <Sidebar
        openMobileMenu={openMobileMenu}
        setOpenMobileMenu={setOpenMobileMenu}
        openMobileProfile={openMobileProfile}
        setOpenMobileProfile={setOpenMobileProfile}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Header />
        <Container
          sx={{
            flexGrow: 1,
            my: { xs: 2, md: 5 },
          }}
        >
          <Routes />
          <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }} />
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
