import { LinkProps, Link as MuiLink, styled } from "@mui/material";
import { IconLaunch } from "../Icons";

const StyledLink = styled(MuiLink)`
  text-decoration-color: inherit;
  &:hover {
    text-decoration-thickness: 3px;
  }
  &:focus {
    outline-color: inherit;
    outline-offset: 3px;
    text-decoration-thickness: 3px;
  }
`;

const Link = (props: LinkProps | (LinkProps & { to: string })) => {
  return (
    <StyledLink {...props}>
      {props.children}
      {props.target === "_blank" ? <IconLaunch sx={{ height: "1ex" }} /> : null}
    </StyledLink>
  );
};

export default Link;
